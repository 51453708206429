<template>
  <div>
    <div class="upcomingBkarea">
      <Upcoming :Year="tm_params.tm_params.season_code" :config="config" :DEFIMAGESAMS3="DEFIMAGESAMS3"/>
    </div>
    <b-card title="Card Title" no-body class="card-remove-border">
      <b-container>
        <b-row class="TitleArea PadTop">
            <b-col sm="12" lg="4">
              <h1 class="heading extraPad">Full Schedule</h1>
            </b-col>
            <b-col sm="12" lg="4">
              <b-card-header header-tag="nav" class="dropdown-seasons">
                <b-nav card-header pills>
                  <b-dropdown :text="this.id + ' Season'">
                    <b-dropdown-item
                      v-on:click="changeYear"
                      v-for="(seasons,
                      index) in tm_params.seasons.seasons_entry.slice().reverse()"
                      :key="index"
                      :title="seasons.code"
                      :year="seasons.code"
                      >{{ seasons.desc }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-nav>
              </b-card-header>
            </b-col>
            <b-col sm="12" lg="4" class="Searchbox">
              <span class="FilterYear">
                <div class="outerInput">
                  <input onfocus="this.placeholder = ''" onblur="this.placeholder = 'Find Tournament'" class="form-control Findatourn" type="text" v-model="search" placeholder=" Find Tournament" />
                  <div>
                    <img class="searchMag" src="https://assets.asiantour.com/asian-tour/2023/12/Icon_feather-search.png">
                  </div>
                  <!-- <font-awesome-icon :icon="['fa', 'magnifying-glass']" /> -->
                  <!-- <b-form-input class="Findatourn" id="filter-input" name="gsearch" v-model="filter" placeholder="Find a Tournament"></b-form-input> -->
                </div>
              </span>
            </b-col>
        </b-row>
        <ScheduleTable
          :fields="fields"
          :id="id"
          :data="data"
          :code="tm_params.code"
          :search="search"
          :months="months"
          :tournDates="tournNewDates"
          :newReports="newReports"
        />
      </b-container>
    </b-card>
  </div>
</template>

<script>
import ScheduleTable from "@/components/scheduleTable.vue";
import Upcoming from "@/components/slider.vue";
import apiCall from "@/services/api.js";
export default {
  name: "schedule",
  props: ["config"],
  components: {
    ScheduleTable,
    Upcoming
  },
  data() {
    return {
      id: [],
      tm_params: [],
      data: [],
      fields: [
        { key: "start_date", label: "Dates" },
        { key: "full_name", label: "Tournament" },
        { key: "course", label: "Venue" },
        { key: "total_prize_fund", label: "Prize Fund" , class: 'fundClass'},
        { key: "reports", label: "Reports", class: 'ReportClass' },
      ],
      search: '',
      months: [
        {
          value: 'Jan',
          full_name: 'January'
        },
        {
          value: 'Feb',
          full_name: 'February'
        },
        {
          value: 'Mar',
          full_name: 'March'
        },
        {
          value: 'Apr',
          full_name: 'April'
        },
        {
          value: 'May',
          full_name: 'May'
        },
        {
          value: 'Jun',
          full_name: 'June'
        },
        {
          value: 'Jul',
          full_name: 'July'
        },
        {
          value: 'Aug',
          full_name: 'August'
        },
        {
          value: 'Sep',
          full_name: 'September'
        },
        {
          value: 'Oct',
          full_name: 'October'
        },
        {
          value: 'Nov',
          full_name: 'November'
        },
        {
          value: 'Dec',
          full_name: 'December'
        },
      ],
      tournNewDates: [],
      arr: [],
      newReports: []
    };
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        this.callTwo(id)
      );
    },
    filterDates: function() {
        let arr = [];
        this.tournDates = arr;
        this.data.forEach((value) => {
            arr.push(value.dic_dates.slice(0,3));
        });

        let uniqueArray = [];
        let arrayWithDuplicates = this.tournDates;
        this.tournNewDates = uniqueArray;
        arrayWithDuplicates.forEach((value) => {
            if (!uniqueArray.includes(value)) {
                uniqueArray.push(value);
            }
        });
        console.log("tournNewDates");
        console.log(this.tournNewDates);
    },
    callOne() {
      apiCall
        .tmParamsBase()
        .then(({ data }) => {
          this.tm_params = data;
          this.id = data.tm_params.season_code;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
      return console.log("one");
    },
    callTwo() {
      apiCall
        .tmParamsYear(this.id)
        .then(({ data }) => {
          this.data = data.tournaments.tournaments_entry;

          // looping through tournaments to get reports arrays
          let arr = [];
          this.arr = arr;
          this.data.forEach((value) => {
              arr.push(value.reports.reports_entry);
              console.log("value");
              console.log(value.reports.reports_entry);
          });
          
          // looping through reports to filter out stats ect
          const oldReports = this.arr[0];
          const newReports = [];
          this.newReports = newReports;

          for (let i = 0; i < oldReports.length; i++) {
            if (oldReports[i].report_title !== "Statistics Tables" && oldReports[i].report_title !== "Statistics" && oldReports[i].report_title !== "Scoring Statistics" && oldReports[i].report_title !== "Course Statistics") {
              newReports.push(oldReports[i]);
            }
          }
          console.log("New Reports: " + newReports);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
      return console.log("two");
    },
  },
  async mounted() {
    // this.callTwo()
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.filterDates();
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParamsYear(this.id);
      this.data = second_call.data.tournaments.tournaments_entry;
      this.filterDates();
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep th.fundClass > div {
  position: relative;
  left: -100px;
}
::v-deep th.ReportClass > div {
  position: relative;
  left: -50px;
}
::v-deep td.fundClass > span {
  position: relative;
  left: -100px;
}
::v-deep td.ReportClass > ul > a > .reportBTN {
  position: relative;
  left: -45px;
}
.Searchbox.col {
  padding-top: 11px;
}
img.searchMag {
  position: absolute;
  right: 40px;
  top: 30px;
}
::v-deep .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
  float: right;
  /* width: 244px; */
  height: 52px;
  background: transparent;
  color: #8b8a8a;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
    margin-top: 10px;
}
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #263056 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.row.TitleArea.PadTop {
  padding-top: 40px;
}
h1.heading.extraPad {
  /* padding: 20px; */
}
.heading{
  font-weight: 600;
  font-size: 26px;
  color: #263056;
  position: relative;
  top: 25px;
}
::v-deep a.allNewsLink.router-link-exact-active.router-link-active {
  display: none;
}
.upcomingBkarea {
  background-color: #F8F8F8;
  padding: 45px;
  padding-right: 0;
  padding-left: 0;
}
.card-remove-border {
  border: none;
}
nav.card-header.dropdown-seasons {
  padding-left: 30px;
  background: none;
  border: none;
  border-top: 0px;
  float: right;
}
::v-deep button {
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #263056;
  color: #ffffff;
  border-radius: 0px;
  border: 0px;
  height: 30px;
  width: 100%;
  text-align: left;
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #8b8a8a;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
::v-deep button::before{
  padding-left: 10px;
}
::v-deep button::after {
  float: right;
  margin-top: 8px;
}
.dropdown {
  /* width: 25%; */
}
::v-deep button:hover {
  color: #263056;
  background-color: white;
}
::v-deep tr:nth-child(even) {
  background-color: #ffff;
}
@media only screen and (max-width: 990px) {
  ::v-deep .dropdown {
    width: 100%!important;
  }
  nav.card-header.dropdown-seasons {
    padding-left: 10px;
    background: none;
    border: none;
    border-top: 0px;
    float: none;
    margin-top: 30px;
  }
  img.searchMag {
    position: absolute;
    right: 40px;
    top: 30px;
  }
}
@media only screen and (max-width: 425px) {
  .dropdown {
    width: 40%;
  }
}
.tourLink > a {
    font-weight: 500;
    padding: 0.5rem 0;
    color: #000000 !important;
    font-size: 16px;
}
</style>
