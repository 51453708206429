<template>
  <div class="stats">
    <b-card title="Card Title" no-body class="card-NoBorder">
      <b-container>
        <b-row>
          <b-col>
            <h3 class="heading">{{ data.short_name }} - {{ data.last_tournament }} - {{ data.date_valid }}</h3>
          </b-col>
          <b-col>
            <b-card-header header-tag="nav" class="statsCard-Header">
              <b-nav card-header pills>
                <b-dropdown :text="this.id + ' Season'">
                  <b-dropdown-item
                    v-on:click="changeYear"
                    v-for="(seasons, index) in noData"
                    :key="index"
                    :title="seasons.code"
                    :year="seasons.code"
                    >{{ seasons.desc }}</b-dropdown-item
                  >
                </b-dropdown>
                <b-dropdown v-if="stats.stats === ''" :text="'Report'">
                  <b-dropdown-item>No Stats</b-dropdown-item>
                </b-dropdown>
                <b-dropdown v-else :text="data.full_name">
                  <b-dropdown-item
                    v-on:click="pickStat"
                    :title="'-ooms-oom-' + stats.ooms.ooms_entry.code"
                    >{{ stats.ooms.ooms_entry.full_name }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-on:click="pickStat"
                    v-for="(stats, index) in stats.stats.stats_entry"
                    :key="index"
                    :title="'-stats-stats-' + stats.code"
                    v-show="stats.show_app == 'B' || stats.show_app == 'W'"
                    >{{ stats.full_name }}</b-dropdown-item
                  >
                </b-dropdown>
              </b-nav>
            </b-card-header>
          </b-col>
        </b-row>
        <div class="title">
        </div>
        <StatsTable v-if="stats.length === 0" :id="id" :config="config" />
        <StatsTable v-else :id="id" :stat="stat" :data="data" :config="config" />
      </b-container>
    </b-card>

			<!--   <iframe style="width:100%" src="https://info.alpstourgolf.com/tic/tmtic.cgi" width="944" height="1150" frameborder="0"></iframe> -->
  </div>
</template>

<script>
import StatsTable from "@/components/statsTable.vue";
import apiCall from "@/services/api.js";
export default {
  name: "stats",
  components: {
    StatsTable,
  },
  props: ["config"],
  data() {
    return {
      stats: [],
      id: "",
      stat: "",
      data: [],
      tm_params: [],
    };
  },
  computed: {
    noData: function() {
      return this.tm_params.seasons.seasons_entry
        .slice()
        .reverse()
        .slice(0, 4);
    },
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (this.id = id), this.callTwo(id), this.callThree(id, this.stat);
    },
    pickStat: function(event) {
      var stat = event.target.getAttribute("title");
      return (this.stat = stat), this.callThree(this.id, stat);
    },
  callOne() {
    apiCall
        .tmParamsBase()
        .then(({ data }) => {
          this.tm_params = data;
          this.id = data.tm_params.season_code;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }),
      console.log("one")
    },
   callTwo() {
     console.log(this.id)
      apiCall
        .tmStats(this.id)
        .then(({ data }) => {
          this.stats = data;
          this.stat = "-ooms-oom-" + data.ooms.ooms_entry.code;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }),
      console.log("two");
    },
    callThree() {
       apiCall
        .stat(this.id, this.stat)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }),
      console.log("three"); 
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmStats(this.id);
      this.stats = second_call.data;
      this.stat = "-ooms-oom-" + second_call.data.ooms.ooms_entry.code;
      if ( this.stats.oom == '') {
        this.stat = "-ooms-oom-" + 'AT';        
      } else {
        this.stat = "-ooms-oom-" + second_call.data.ooms.ooms_entry.code; 
      }
      this.loading = false;
      // Third call, await for call to return
      let third_call = await apiCall.stat(this.id, this.stat);
      this.data = third_call.data;
      this.loading = false; 
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>
<style scoped>
  h3.heading {
    font-weight: 600;
    font-size: 26px;
    color: #263056;
  }
  ::v-deep .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #263056 !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .card-header {
    padding: 0.75rem 0rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  ::v-deep td {
    font-size: 16px;
    font-weight: 600;
  }
  .title {
    text-align: center;
    background-color: #4E5F9B;
    color: white;
    text-transform: capitalize;
    border-top-left-radius: 30px!important;
    border-top-right-radius: 30px!important;
    margin-top: 50px;
    height: 40px;
  }
  .title > h3 {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    font-size: 24px;
    text-transform: uppercase;
  }
  .title > p {
    font-size: 16px !important;
    margin-bottom: 0;
  }
  .stats {
    padding-top: 50px;
  }
  ::v-deep .btn {
    background-color: #263056;
  }
  .statsCard-Header {
    background-color: transparent;
    border-bottom: 0px solid;
    float: right;
    padding-top: 0;
  }
  .card-NoBorder {
    border: 0px solid;
  }
  div#__BVID__56 {
    padding-right: 10px;
  }
  .dropMenu {
    text-align: center;
    padding-top: 10px;
  }
  .dropdown {
    /* width: 20%; */
    padding: 0 10px;
  }

  ::v-deep .btn {
    font-size: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #263056;
    color: #ffffff;
    border-radius: 0px;
    border: 0px;
    height: 30px;
    width: 100%;
    text-align: left;
    float: right;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #8b8a8a;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
  }

  ::v-deep .btn:after {
    float: right;
    margin-top: 10px;
  }
  @media only screen and (max-width: 990px) {
  .dropdown {
      width: 100%;
      margin-top: 20px;
  }
  }
  @media only screen and (max-width: 480px) {
    ::v-deep .dropdown-menu {
      left: -1em !important;
      text-align: center;
    }
  }
</style>
