<template>
  <b-card-body class="text-center">
    <div class="table-responsive">
        <!-- <div v-for="(month, index) in months" :key="index" >
          <div :class="{'adtDevider' : develop == 'adt'}" class="monthRow" v-for="(value) in tournDates" :key="value" v-show="value.includes(month.value)">
            <div class="container monthindex">{{month.full_name}} {{id}}</div>
          </div> -->
          <b-table :items="filteredList" :fields="fields" :tbody-tr-class="rowClass"> 
            <template #cell(reports)="row">
              <b-nav card-header pills>
                <template v-if="row.item.reports.reports_entry == undefined">
                   <!-- <div style="text-align:left"> No Reports</div> -->
                </template>
                <template v-else>
                  <template v-if="row.item.ts_final_result == 'Y'">
                    <b-link 
                    :to="{
                      name: 'reports',
                      query: {
                      url:
                        'https://ocs-ffg.com/tic/tmresult.cgi?tourn=' +
                        row.item.code +
                        '~season=' +
                        id +
                        '~result=PF~',
                        id: id,
                        code: row.item.code,
                        title: 'Final Result',
                      },
                    }"                   
                    >
                      <button class="reportBTN">
                        Results
                      </button>
                    </b-link>
                  </template>
                  <template v-else-if="Array.isArray(row.item.reports.reports_entry)">
                    <b-link v-for="(rep, index) in row.item.reports.reports_entry.slice(-1)" :key="index" 
                      :to="{
                        name: 'reports',
                        query: {
                          url: rep.report_url,
                          id: id,
                          code: row.item.code,
                          title: rep.report_title,
                        },
                      }"                   
                    >
                      <button class="reportBTN">
                        <template v-if="rep.report_title == 'Live Scoring Monitor'">
                          Live Scores
                        </template>
                      </button>
                    </b-link>
                  </template>
                  <template v-else>
                      <b-link :to="{
                          name: 'reports',
                          query: {
                            url: row.item.reports.reports_entry.report_url,
                            id: id,
                            code: row.item.code,
                            title: row.item.reports.reports_entry.report_title,
                          },
                        }">
                          <button class="reportBTN">
                            Entries 
                          </button>
                      </b-link>
                  </template>
                </template>
              </b-nav>
            </template>
            <template #cell(full_name)="row">
              <b-nav-item
                class="tourLink"
                :to="'/tournament/' + row.item.code + '/' + id"
                :class="{major : row.item.major == 'Y' }"
                :filter="filter"
              >
                {{ row.item.full_name }}
              </b-nav-item>
            </template>
            <template #cell(start_date)="row" >
              <span :class="{major : row.item.major == 'Y'}">
                {{ row.item.start_date }}
              </span>
            </template>
            <template #cell(course)="row">
              <span :class="{major : row.item.major == 'Y'}">
                {{ row.item.course }}
              </span>
            </template>
            <template #cell(total_prize_fund)="row">
              <span :class="{major : row.item.major == 'Y'}">
                {{ row.item.total_prize_fund }}
              </span>
            </template>
          </b-table>
        <!-- </div> -->
    </div>
  </b-card-body>
</template>

<script>
export default {
  name: "Schedule Table",
  props: ["id", "fields", "data", "code", "search", "months", "tournDates" , "newReports"],
  filters: {
    base: function(value) {
      return btoa(value);
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.code === item.code) return "live";
    },
  },
  computed: {
    filteredList() {
      return this.data.filter(item => {
        const query = this.search.toLowerCase(); 
        return item.full_name.toLowerCase().includes(query);
      })
    },
  },
};
</script>

<style scoped>
::v-deep thead > tr>  th {
  border-top: 0px solid #dee2e6;
}
tr.live > td > ul > a > button {
  background-color: #fff;
}
ul.nav.nav-pills.card-header-pills {
  display: block;
}
button.reportBTN {
  text-align: center;
  width: 100%;
  color: #000 !important;
  font-weight: 600;
}
button.reportBTN:hover {
  text-align: center;
  width: 100%;
  color: #fff !important;
  background-color: #263056;
  font-weight: 600;
}
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  color: #ffff;
}
::v-deep div#dropdown-dropleft > button {
  width: 150px;
  height: 35px;
  vertical-align: middle;
  padding-top: 7px;
}
::v-deep li.nav-item.tourLink > a{
  color: #000!important;
  font-weight: 600;
}
::v-deep li.nav-item.tourLink > a:hover{
  color: #263056!important;
  font-weight: 600;
}
::v-deep th:first-child {
  text-align: center;
  border-top-left-radius: 30px;
}
::v-deep th:last-child {
  text-align: center;
  border-top-right-radius: 30px;
}
::v-deep .dropdown-toggle::after {
  transform: rotate(-90deg);
}
::v-deep .dropleft .dropdown-toggle::after {
  vertical-align: 0;
}
::v-deep .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
::v-deep .dropleft .dropdown-toggle::before {
  display: none;
}
.major {
  color: #263056!important;
  font-weight: bold!important;
}
.major > a {
  color: #263056!important;
  font-weight: bold!important;
}
::v-deep .live > td {
  color: #fff;
}
::v-deep .live > td > span {
  color: #848792!important;
}
li {
  list-style: none;
}
.card-body {
  padding-left: 0;
  padding-right: 0;
}
::v-deep table {
  text-align: left;
}
::v-deep th:first-child {
  text-align: center;
}
::v-deep td:first-child {
  text-align: center;
  padding-top: 33px;
  padding-bottom: 33px;
}
::v-deep th {
  background-color: #263056;
  font-size: 16px;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 111;
  font-weight: normal;
  text-align: left;
}
::v-deep td {
  font-size: 15px !important;
  line-height: 18px;
  color: #848792;
  vertical-align: middle;
  padding: 1px 1rem;
  text-align: left;
}
.tourLink > a {
  font-weight: bold;
  padding: 0.5rem 0;
}
.tourLink > a:hover {
  color: #263056!important;
}
::v-deep .live {
  color: white;
  background-color: #F8F8F8 !important;
}
::v-deep .live > td > li > a {
  color: white !important;
}

::v-deep .live > td > ul > div > button {
  color: #263056;
  background-color: white;
}
::v-deep .btn-secondary {
  color: #000;
  background-color: #ffffff;
  text-align: left;
  font-weight: normal;
}
.dropdown {
  /* border: 1px solid black; */
}
::v-deep .dropdown > button {
  font-size: 13px;
  padding-top: 2px;
  /* height: 20px; */
}
::v-deep .dropdown-toggle::before {
  transform: rotate(-90deg);
}
@media only screen and (max-width: 1222px) {
  ::v-deep button::after {
    float: right;
    margin-top: -12px!important;
    position: relative;
    right: -7px;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep th.ReportClass > div{
    display: none;
  }
  td.ReportClass > ul{
    display: none!important;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .dropdown-toggle {
    font-size: 14px;
  }
  ::v-deep .dropdown {
    /* width: 92%; */
    width: 100%;
  }
  ::v-deep tr > th:nth-child(4) {
    display: none;
  }
  ::v-deep tr > td:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep tr > td:nth-child(3) {
    display: none;
  }
  ::v-deep tr > th:nth-child(3) {
    display: none;
  }
}
</style>
