<template>
  <div>
  <b-table :fields="fields" :items="stats"> </b-table>
    <h2 v-if="stats == undefined" class="Nodata">No Data</h2>
  </div>

</template>

<script>
export default {
  name: "playerstats",
  props: ["stats"],
  data() {
    return {
      fields: [
        { key: "DESCRIPTION", label: "Description" },
        { key: "SEASONS", label: "Seasons", class: "hidMob" },
        { key: "TOURNAMENTS", label: "Tournaments" },
        { key: "VALUE", label: "Result" },
      ],
    };
  },
};
</script>

<style scoped>
.Nodata {
  text-align: center;
  color: #263056;
  font-size: 26px;
  font-weight: bold;
  padding-top: 20px;
}
::v-deep thead {
  color: white;
  background-color: #263056;
}
::v-deep tbody > tr:nth-child(even) {
  background-color: #F5F5F5;
}
::v-deep td {
  text-align: center;
  padding: 6px 6px 6px 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 14px;

}
::v-deep th {
  padding: 13px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
}
::v-deep td:first-child{
  text-align: left;
  width: 20%;
}
::v-deep th:first-child{
  text-align: left;
}
@media only screen and (max-width: 990px) {
  ::v-deep th.hidMob {
    display: none;
  }
  ::v-deep td.hidMob {
    display: none;
  }
}
</style>
