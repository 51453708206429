<template>
  <div>
      <b-row>
        <b-col>
          <!-- <div class="bimg"> -->
          <div class="articleImage" v-if="postInfo.better_featured_image !== null" v-bind:style="{ 'background-image': 'url(' + postInfo.better_featured_image.source_url + ')' }">
          </div>
          <b-container>
            <h2 class="article-heading">
              <span v-html="postInfo.title.rendered"></span>
            </h2>
            <div class="mainArt">
              <span
                v-html="postInfo.content.rendered"
                class="newsDescript"
              ></span>
            </div>
          </b-container>
        </b-col>
      </b-row>
      <b-container>
        <div class="allPosts">
          <b-row class="latestAlpsNews">
            <b-col class="postRow">
              <Post :config="config" />
            </b-col>
          </b-row>
        </div>
      </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Post from "@/components/posts.vue";
export default {
  name: "postFull",
  props: ["id", "config"],
  components: {
    Post
  },
  data() {
    return {
      postInfo: []
    };
  },
  created() {
    const json = "wp/v2/posts/" + this.id;
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.postInfo = data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
.articleImage {
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.captionText {
  background-color: #263056;
  margin-top: 10px;
  color: #fff;
  font-size: 13pt;
  padding: 10px;
}
.newslist {
  margin-top: 2em;
}
h2.article-heading {
  color: #263056;
  font-size: 26px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 30px;
}
.newsDescript {
  font-size: 20px;
  /* padding: 20px 0; */
  color: #707070;
}
.mainArt {
  padding: 25px 0;
  padding-bottom: 100px;
}
.latest {
  background-color: #dfe1e6;
  color: #263056;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bimg {
  overflow: hidden;
  height: 500px;
}
.artImg {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .bimg {
    overflow: hidden;
    height: 300px;
  }
}
@media only screen and (max-width: 990px) {
  .latestAlpsNews {
    padding-left: 15px;
    padding-right: 15px;
  }
  ::v-deep #leftMore {
    color: #fff;
    background-color: #263056;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    padding: 3px;
    text-align: left;
    vertical-align: bottom;
  }
  ::v-deep .moreNews>a  {
    background-color: transparent;
    color: #263056!important;
    text-align: left;
    /* vertical-align: middle; */
    margin-top: -12px;
    margin-bottom: 15px;
    padding-left: 5px;
  }
}
</style>
