<template>
  <div>
    <b-row>
      <b-col
        class="col-md-4 col-sm-4 col-12"
        v-for="(gallery, index) in videos.items"
        :key="index"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="
            'https://www.youtube.com/embed/' +
              gallery.contentDetails.videoId
          "
          allowfullscreen
        ></b-embed>
        <!-- <h4 class="videoText">Video</h4> -->
        <p class="galleryTitle">
          {{ gallery.snippet.title }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import apiCall from "@/services/api.js";
import axios from 'axios';
export default {
  name: "youTube",
  data() {
    return {
      videos: [],
      youtubeChannel: '',
      youtubeAPI: ''
    };
  },
  props: [
    'config'
  ],
  methods: {
    getYoutube: function() {
      axios
        .get(
            "https://api.euro.ocs-software.com/ffg/cache/alp/tmticx?randomad=" +
              new Date().getTime()
        )
        .then((response) => {
          // this.feature = response.data;
            console.log('FirstCall')
            this.youtubeAPI = response.data.tm_params.youtube_keys.youtube_key.youtube_api;
            this.youtubeChannel = response.data.tm_params.youtube_keys.youtube_key.youtube_channel;
            return axios.get(
            'https://api.euro.ocs-software.com/youtube/videos?playlistId=' + this.youtubeChannel + '&key=' + this.youtubeAPI + '&maxResults=10&type=video&order=date&part=snippet,contentDetails&'
            ) .then((response) => {
              this.videos = response.data;
              console.log('SecondCall')
            });
        });
    }
  },
  mounted() {
    this.getYoutube();
  },
};
</script>

<style scoped>
.row {
  padding-bottom: 20px;
}
.col {
  padding-bottom: 20px;
}
h4.videoText {
  font-size: 20pt;
  margin-top: 30px;
}
p.galleryTitle {
  font-size: 18px;
  margin-top: 20px;
  font-weight: 600;
  color: #000;
}
@media only screen and (max-width: 480px) {
  .mobHide {
    display: none;
  }
}
</style>
