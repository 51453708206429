<template>
  <div>
    <div class="newSearch">
      <b-container>
        <b-jumbotron :header="'Search \'' + filter + '\''">
          <b-form-input
            class="seachInput"
            v-model="text"
            placeholder="Search"
            @keyup.enter="goSearch()"
          ></b-form-input>
          <b-button
            :to="{
              name: 'search',
              query: { search: text },
            }"
            class="sIcon"
          >
          </b-button>
        </b-jumbotron>
      </b-container>
    </div>
    <b-container>
      <b-table
        v-if="filter"
        :items="allposts"
        :fields="fields"
        stacked="md"
        show-empty
        small
        :filter="filter"
      >
        <template #cell(better_featured_image)="row">
          <b-nav-item :to="'/news/' + row.item.id">
            <h2 v-html="row.item.title.rendered"></h2>
          </b-nav-item>
          <span v-html="row.item.excerpt.rendered"></span> </template
      ></b-table>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "search",
  data() {
    return {
      allposts: [],
      filter: this.$route.query.search,
      fields: [{ key: "better_featured_image", label: "" }],
      filterOn: [],
      text:''
    };
  },
  methods: {
    goSearch() {
      console.log('Go search')
      // this.text = text
      this.$router.push({ name: "search", query: { search: this.text } });
    },
  },
  created() {
    const json = "wp/v2/posts?per_page=100";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.allposts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep tr:nth-child(even) {
  background-color: #fff
}
h2 {
  font-size: 32px;
  text-align: left;
  color: #263056;
}
::v-deep p {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #707070;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
::v-deep .jumbotron {
  padding-left: 0;
}
li {
  list-style-type: none;
}
.nav-link {
  padding: 0.5em 0;
}
::v-deep table {
  margin-top: 75px;
}
.newSearch {
  background-color: #eee;
  /* margin-top: 75px; */
}
::v-deep .display-3 {
  font-size: 30px;
  font-weight: 500;
}
.seachInput {
  width: 25%;
  display: inline-block;
  margin-top: 1em;
}
.sIcon {
  color: white !important;
  background-color: transparent;
  padding: 5px 11px;
  border-radius: 5px;
  border: none;
}
.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15sease-in-out, box-shadow 0.15sease-in-out;
}
</style>
