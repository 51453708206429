<template>
  <div class="tour">
    <div class="top" v-bind:style="{ 'background-image': 'url(' + 'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/courses/' + id + '/' +  tourinfo.course_code + '/' + tourinfo.course_image_file_1 + ')' }">
       <!-- Show Desktop -->
      <div class="desktopOnly">
        <b-row :class="[splashText]">
          <b-col :class="[overlay]" class="leftCol" lg="12" md="12" cols="12">
            <b-row>
              <b-col style="text-align:center;" lg="2" md="2" cols="6">
                <div data-v-16b2276d="" class="outerBorderImage">
                  <div data-v-16b2276d="">
                    <img data-v-16b2276d="" :src="tourinfo.sponsor_logo" class="sponsorImage">
                  </div>
                </div>
              </b-col>
              <b-col lg="10" md="10" cols="6">
                <h2 class="date-width">
                  {{ tourinfo.dates }}
                </h2>
                <h2 class="fullName-width">
                  {{ tourinfo.full_name }}
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <!-- Show Mobile -->
      <div class="showMobile">
        <h2 class="date-width">
          {{ tourinfo.dates }}
        </h2>
        <h2 class="fullName-width">
          {{ tourinfo.full_name }}
        </h2>
      </div>
    </div>

    <!-- Tournament Information Section -->
    <!-- Desktop Section -->
    <div class="desktopOnly">
      <div class="backgroundGrey">
        <b-container>
          <b-row>
            <b-col class="leftCol" style="padding-right:1px;">
              <h2 class="splashPad">
                Tournament Info 
              </h2>
              <b-row>
                <b-col lg="12" class="noPadLeft">
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Course Dates
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.dates }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Rounds
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.rounds }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Prize Fund
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.total_prize_fund }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Field Size
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.field_size }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad" v-if="tourinfo.previous_winners.previous_winners_entry.cc_name_1 !== ''">
                    <b-col lg="4">
                      <h3 class="underLine">
                      Previous Winner
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.previous_winners.previous_winners_entry.cc_name_1 }}
                      </h3>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="leftCol">
              <h2 class="splashPad">
                About the Course
              </h2>
              <b-row>
                <b-col lg="12" class="noPadLeft">
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Course Name
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.course_name }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        City
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.course_city }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Country
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.course_country }}
                      </h3>
                    </b-col>
                  </b-row>
                  <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Website
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        <a
                          :href="tourinfo.course_website"
                          rel="noopener"
                          target="_blank"
                        >
                          {{ tourinfo.course_website }}
                        </a>
                      </h3>
                    </b-col>
                  </b-row>
                <b-row class="rowPad">
                    <b-col lg="4">
                      <h3 class="underLine">
                        Par
                      </h3>
                    </b-col>
                    <b-col>
                      <h3 class="valuesize rightside-changes">
                        {{ tourinfo.course_total_par }}
                      </h3>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="leftCol">
              <h2 class="splashPad" v-if="tourinfo.winner_found === 'Y'">
                Winner
              </h2>
              <h2 class="splashPad" v-else>
                Previous Winner
              </h2>
              <template v-if="tourinfo.winner_found === 'Y'">
                <b-row>
                  <b-col lg="4" class="imagePad">
                    <template v-if="tourinfo.winner_image == 'Y'">
                      <b-img
                        class="mainImg"
                        :src="
                          'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' +
                          tourinfo.winner_code + '.jpg'
                        "
                      ></b-img>
                    </template>
                    <template v-else>
                      <b-img
                        class="mainImg"
                        :src="
                          'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/06/dummy-image.jpg'
                        "
                      ></b-img>
                    </template>
                  </b-col>
                  <b-col lg="8" class="winnerNameBlock">
                    <p>
                      <a :href="'/playerprofile/' + tourinfo.winner_code" class="playerProfileLink">
                        {{ tourinfo.winner_name }}
                      </a>
                    </p>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-row>
                  <b-col lg="4" class="imagePad">
                    <template v-if="tourinfo.previous_winners.previous_winners_entry.cc_code_1 !== ''">
                      <b-img
                        class="mainImg"
                        :src="
                          'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' +
                          tourinfo.previous_winners.previous_winners_entry.cc_code_1 + '.jpg'
                        "
                      ></b-img>
                    </template>
                    <template v-else>
                      <b-img
                        class="mainImg"
                        :src="
                          'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/06/dummy-image.jpg'
                        "
                      ></b-img>
                    </template>
                  </b-col>
                  <b-col lg="8" class="winnerNameBlock">
                    <p>
                      <a :href="'/playerprofile/' + tourinfo.previous_winners.previous_winners_entry.cc_code_1" class="playerProfileLink">
                        {{ tourinfo.previous_winners.previous_winners_entry.cc_name_1 }}
                      </a>
                    </p>
                      <img
                        v-if="tourinfo.previous_winners.previous_winners_entry.cc_nat_1 != ''"
                        class="insideflag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            tourinfo.previous_winners.previous_winners_entry.cc_nat_1)
                            | lowercase
                        "
                      />
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <!-- Mobile Section -->
    <div class="showMobile">
      <h2 class="HeaderText">
        Tournament Info 
      </h2> 
      <div class="marginRow">
        <b-row>
          <b-col>
            <h3 class="underLine">
              Course Dates
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.dates }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              Rounds
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.rounds }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              Prize Fund
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.total_prize_fund }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              Field Size
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.field_size }}
            </h3>
          </b-col>
        </b-row>
      </div>
      <h2 class="HeaderText">
        About the Course
      </h2> 
      <div class="marginRow">
        <b-row>
          <b-col>
            <h3 class="underLine">
              Course Name
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.course_name }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              City
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.course_city }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              Country
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.course_country }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              Website
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              <a
                :href="tourinfo.course_website"
                rel="noopener"
                target="_blank"
                class="websiteLink"
              >
                {{ tourinfo.course_website }}
              </a>
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="underLine">
              Par
            </h3>
          </b-col>
          <b-col>
            <h3 class="valuesize rightside-changes">
              {{ tourinfo.course_total_par }}
            </h3>
          </b-col>
        </b-row>
      </div>
      <div class="marginRow">
        <b-row>
            <b-col class="leftCol">
              <h3 class="HeaderText space" v-if="tourinfo.winner_found === 'Y'">
                Winner
              </h3>
              <h3 class="HeaderText space" v-else>
                Previous Winner
              </h3>
              <template v-if="tourinfo.winner_found === 'Y'">
                <b-row class="">
                  <b-col lg="4" class="imagePad">
                    <template v-if="tourinfo.winner_image == 'Y'">
                      <b-img
                        class="mainImg"
                        :src="
                          'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' +
                          tourinfo.winner_code + '.jpg'
                        "
                      ></b-img>
                    </template>
                    <template v-else>
                      <b-img
                        class="mainImg"
                        :src="
                          'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/06/dummy-image.jpg'
                        "
                      ></b-img>
                    </template>
                  </b-col>
                  <b-col lg="8" class="winnerNameBlock">
                    <p>
                      <a :href="'/playerprofile/' + tourinfo.winner_code" class="playerProfileLink">
                        {{ tourinfo.winner_name }}
                      </a>
                    </p>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-row>
                  <b-col lg="4" class="imagePad">
                    <template v-if="tourinfo.previous_winners.previous_winners_entry.cc_code_1 !== ''">
                      <b-img
                        class="mainImg"
                        :src="
                          'https://tms-images.ams3.cdn.digitaloceanspaces.com/ffg/media/photos/' +
                          tourinfo.previous_winners.previous_winners_entry.cc_code_1 + '.jpg'
                        "
                      ></b-img>
                    </template>
                    <template v-else>
                      <b-img
                        class="mainImg"
                        :src="
                          'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/06/dummy-image.jpg'
                        "
                      ></b-img>
                    </template>
                  </b-col>
                  <b-col lg="8" class="winnerNameBlock">
                    <p>
                      <a :href="'/playerprofile/' + tourinfo.previous_winners.previous_winners_entry.cc_code_1" class="playerProfileLink">
                        {{ tourinfo.previous_winners.previous_winners_entry.cc_name_1 }}
                      </a>
                    </p>
                      <img
                        class="insideflag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            tourinfo.previous_winners.previous_winners_entry.cc_nat_1)
                            | lowercase
                        "
                      />
                  </b-col>
                </b-row>
              </template>
            </b-col>
        </b-row>
      </div>
    </div>


    <!-- Course Information Section -->
    <div class="coursInfoWritten">
      <b-container>
        <h2 class="HeaderText courseInformation">Course Information</h2>
        <b-row>
          <b-col class="paragraph-Padding">
            <p v-if="Object.keys(tourinfo.course_para1).length === 0"></p>
            <p class="paraText">
              {{ tourinfo.course_para1 }}
            </p>
            <p v-if="Object.keys(tourinfo.course_para2).length === 0"></p>
            <p class="paraText">
              {{ tourinfo.course_para2 }}
            </p>
            <p v-if="Object.keys(tourinfo.course_para3).length === 0"></p>
            <p v-else class="paraText"> 
              {{ tourinfo.course_para3 }}
            </p>
            <p v-if="Object.keys(tourinfo.course_para4).length === 0"></p>
            <p class="paraText">
              {{ tourinfo.course_para4 }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <!-- Reports Section -->
      <div class="reportSele" v-if="tourinfo.reports !== ''">
        <b-row>
          <b-col>
            <div :class="drop" class="reportdrop">
              <h2 class="HeaderText reportInfo">Select Report</h2>
              <b-nav card-header pills align="center">
                <b-dropdown
                  v-if="Array.isArray(tourinfo.reports.reports_entry)"
                  :text="title"
                  :config="config"
                >
                  <b-dropdown-item
                    v-on:click="changeReport"
                    v-for="(reports, index) in tourinfo.reports.reports_entry"
                    :key="index + Math.random()"
                    :title="reports.report_url"
                    :value="reports.report_title"
                    v-show="
                      reports.report_title !== 'Course Statistics' &&
                      reports.report_title !== 'Scoring Statistics' &&
                      reports.report_title !== 'Course Statistics (GPC)' &&
                      reports.report_title !== 'Course Statistics (LOST)' &&
                      reports.report_title !== 'Statistics Tables' &&
                      reports.report_title !== 'Live Scoring Monitor'
                    "
                    >{{ reports.report_title }}</b-dropdown-item
                  >
                </b-dropdown>
                <b-dropdown v-else :text="title">
                  <b-dropdown-item
                    v-on:click="changeReport"
                    v-for="(reports, index) in tourinfo.reports"
                    :key="index + Math.random()"
                    :title="reports.report_url"
                    :value="reports.report_title"
                    >{{ reports.report_title }}</b-dropdown-item
                  >
                </b-dropdown>
              </b-nav>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="multiCourseIndex" v-if="tourinfo.multi_course === 'Y'">
            <span
              v-for="(course, index) in tourinfo.courses.courses_entry"
              :key="index + Math.random()"
              class="multiText"
            >
              {{ course.course_X_id }} =
              <span :class="course.course_X_colour" class="multiDot-size"
                >•</span
              >
            </span>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="tourinfo.reports !== ''">
        <b-col>
          <div class="tourInfo reportSection container tournReportSection">
            <div v-if="title.includes('Score')">
              <div class="TitleArea">
                <span v-if="title.includes('Score')">
                  <h3 class="courseDates TitleAreaScore">
                    {{ data.course_dates }}
                  </h3>
                  <h3 class="courseDates lastRealTime-update">
                    Last Real Time Update -
                    {{ data.last_scores_update_date }} - {{ data.course_city }} Time -
                    {{ data.last_scores_update_time }}
                    {{ data.last_scores_update_date }}
                  </h3>
                  <template v-if="showGender == 'Y'">
                    <p class="showPgender">
                      <span class="multiDot-size-reportgender male">• </span> = Men
                      Professional <br />
                      <span class="multiDot-size-reportgender female">• </span> =
                      Ladies Professional
                    </p>
                  </template>
                </span>
              </div>
              <b-tabs content-class="mt-3" class="tabsLi">
                <template v-if="data.hide_gross_sb == 'Y'">
                  <b-tab title="Leaderboard" active title-item-class="nonactive">
                    <template v-if="data.stableford == 'Y'">
                      <ScoreTeam
                        :data="data"
                        :title="title"
                        :home="false"
                        :config="config"
                        :team="teamEvent"
                        :match="tourinfo.match_play"
                        :code="code"
                        :season="season"
                        :showGender="showGender"
                        :is_qualifier="is_qualifier"
                        :stableford="stableford"
                        :show_info_web="show_info_web"
                        :hide_score_card="hide_score_card"
                      />
                    </template>
                    <template v-else>
                      <ReportTable
                        :data="data"
                        :title="title"
                        :home="false"
                        :config="config"
                        :team="teamEvent"
                        :match="tourinfo.match_play"
                        :code="code"
                        :season="season"
                        :showGender="showGender"
                        :is_qualifier="is_qualifier"
                        :stableford="stableford"
                        :show_info_web="show_info_web"
                        :hide_score_card="hide_score_card"
                      />
                    </template>
                  </b-tab>
                  <b-tab title="Hole-By-Hole" title-item-class="nonactive HBH">
                    <HBH
                      :data="data"
                      :previousData="previousData"
                      :config="config"
                      :title="title"
                      :showGender="showGender"
                      :show_info_web="show_info_web"
                      :hide_score_card="hide_score_card"
                    />
                  </b-tab>
                </template>
                <template v-else>
                  <template v-if="data.tt_code == '2B'">
                    <b-tab
                      active
                      title="Leaderboard"
                      title-item-class="nonactive"
                    >
                      <template v-if="data.stableford == 'Y'">
                        <ScoreTeam
                          :data="data"
                          :title="title"
                          :home="false"
                          :config="config"
                          :team="teamEvent"
                          :match="tourinfo.match_play"
                          :code="code"
                          :season="season"
                          :showGender="showGender"
                          :is_qualifier="is_qualifier"
                          :stableford="stableford"
                          :show_info_web="show_info_web"
                          :hide_score_card="hide_score_card"
                        />
                      </template>
                      <template v-else>
                        <ReportTable
                          :data="data"
                          :title="title"
                          :home="false"
                          :config="config"
                          :team="teamEvent"
                          :match="tourinfo.match_play"
                          :code="code"
                          :season="season"
                          :showGender="showGender"
                          :is_qualifier="is_qualifier"
                          :stableford="stableford"
                          :show_info_web="show_info_web"
                          :hide_score_card="hide_score_card"
                        />
                      </template>
                    </b-tab>
                    <b-tab title="Hole-By-Hole" title-item-class="nonactive HBH">
                      <HBH
                        :data="data"
                        :previousData="previousData"
                        :config="config"
                        :title="title"
                        :showGender="showGender"
                        :show_info_web="show_info_web"
                        :hide_score_card="hide_score_card"
                      />
                    </b-tab>
                  </template>
                  <template v-else>
                    <b-tab title="Leaderboard" active title-item-class="nonactive">
                      <ReportTable
                        :data="data"
                        :title="title"
                        :home="false"
                        :config="config"
                        team="N"
                        :match="tourinfo.match_play"
                        :code="code"
                        :season="season"
                        :showGender="showGender"
                        :is_qualifier="is_qualifier"
                        :stableford="stableford"
                        :show_info_web="show_info_web"
                        :hide_score_card="hide_score_card"
                      />
                    </b-tab>
                    <b-tab
                      title="Team"
                      v-if="teamEvent === 'Y'"
                      title-item-class="nonactive"
                    >
                      <template v-if="data.stableford == 'Y'">
                        <ScoreTeam
                          :data="data"
                          :title="title"
                          :home="false"
                          :config="config"
                          :team="teamEvent"
                          :match="tourinfo.match_play"
                          :code="code"
                          :season="season"
                          :showGender="showGender"
                          :is_qualifier="is_qualifier"
                          :stableford="stableford"
                          :show_info_web="show_info_web"
                          :hide_score_card="hide_score_card"
                        />
                      </template>
                      <template v-else>
                        <ReportTable
                          :data="data"
                          :title="title"
                          :home="false"
                          :config="config"
                          :team="teamEvent"
                          :match="tourinfo.match_play"
                          :code="code"
                          :season="season"
                          :showGender="showGender"
                          :is_qualifier="is_qualifier"
                          :stableford="stableford"
                          :show_info_web="show_info_web"
                          :hide_score_card="hide_score_card"
                        />
                      </template>
                    </b-tab>
                    <b-tab title="Hole-By-Hole" title-item-class="nonactive HBH">
                      <HBH
                        :data="data"
                        :previousData="previousData"
                        :config="config"
                        :title="title"
                        :showGender="showGender"
                        :show_info_web="show_info_web"
                        :hide_score_card="hide_score_card"
                      />
                    </b-tab>
                  </template>
                </template>
              </b-tabs>
            </div>
            <div v-else>
              <ReportTable
                :data="data"
                :title="title"
                :home="false"
                :config="config"
                :team="team"
                :match="tourinfo.match_play"
                :code="code"
                :season="season"
                :showGender="showGender"
                :is_qualifier="is_qualifier"
                :stableford="stableford"
                :show_info_web="show_info_web"
                :hide_score_card="hide_score_card"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Overview Section -->
      <div class="tourInfoOverview" v-if="tourinfo.tourn_desc_found == 'Y'">
          <h2 class="HeaderText">Overview</h2>
          <p class="tourndesc" v-html="tourinfo.tourn_desc">
        </p>
      </div>
      <!-- Scorecard Section -->
      <div class="showDesk">
        <h2 class="HeaderText scorecardSection">Scorecard</h2>
        <template v-if="tourinfo.multi_course === 'Y'">
          <div
            v-for="(multi, index) in tourinfo.courses.courses_entry"
            :key="index"
          >
            <b-row :class="courseStats">
              <b-col>
                <div class="table-responsive">
                  <table class="courseinfo">
                    <thead>
                      <tr class="title" :class="multi.course_X_colour">
                        <th class="titleText">Hole</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>out</th>
                        <th>10</th>
                        <th>11</th>
                        <th>12</th>
                        <th>13</th>
                        <th>14</th>
                        <th>15</th>
                        <th>16</th>
                        <th>17</th>
                        <th>18</th>
                        <th>In</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="titleText">Par</td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_out_par.slice(1, 5) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_in_par.slice(1, 5) }}
                        </td>
                        <td>
                          {{ multi.course_X_total_par }}
                        </td>
                      </tr>
                      <tr>
                        <td class="titleText">Yards</td>
                        <td
                          class="title"
                          v-for="(number, index) in multi.course_X_length_yards
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td class="title">
                          {{ outVal(multi.course_X_length_yards) }}
                        </td>
                        <td
                          class="title"
                          v-for="(number, index) in multi.course_X_length_yards
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td class="title">
                          {{ inVal(multi.course_X_length_yards) }}
                        </td>
                        <td class="title">
                          {{ totalVal(multi.course_X_length_yards) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="titleText">Meters</td>
                        <td
                          v-for="(number, index) in multi.course_X_length_meters
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(multi.course_X_length_meters) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_length_meters
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(multi.course_X_length_meters) }}
                        </td>
                        <td>
                          {{ totalVal(multi.course_X_length_meters) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="tourinfo.multi_course === 'N'">
          <b-row :class="courseStats">
            <b-col>
              <div class="table-responsive">
                <table class="courseinfo">
                  <thead>
                    <tr class="title">
                      <th class="titleText">Hole</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>out</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                      <th>16</th>
                      <th>17</th>
                      <th>18</th>
                      <th>In</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="titleText"><span class="firstOption">Par</span></td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_out_par.slice(1, 5) }}
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_in_par.slice(1, 5) }}
                      </td>
                      <td>
                        {{ tourinfo.course_total_par }}
                      </td>
                    </tr>
                    <tr>
                      <td class="title titleText"> <span class="firstOption">Yards</span></td>
                      <td
                        class="title"
                        v-for="(number, index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td class="title">
                        {{ outVal(tourinfo.course_length_yards) }}
                      </td>
                      <td
                        class="title"
                        v-for="(number, index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td class="title">
                        {{ inVal(tourinfo.course_length_yards) }}
                      </td>
                      <td class="title">
                        {{ totalVal(tourinfo.course_length_yards) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="titleText"><span class="firstOption">Meters</span></td>
                      <td
                        v-for="(number, index) in tourinfo.course_length_meters
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ outVal(tourinfo.course_length_meters) }}
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_length_meters
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ inVal(tourinfo.course_length_meters) }}
                      </td>
                      <td>
                        {{ totalVal(tourinfo.course_length_meters) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </template>
      </div>
      <!-- News Section -->
      <b-row class="tournNews" v-if="showNews">
        <b-col sm="12" lg="6">
          <h2 class="HeaderText">Tournament News</h2>
        </b-col>
        <b-col sm="12" lg="6">
          <b-link to="/news" class="allNewsLink">
            <button class="Allbuttons">
              See More News
            </button>
          </b-link>
        </b-col>
      </b-row>
      <Post v-if="wptag.length"
        :tag="wptag[0].id" 
        :count="wptag[0].count" 
        :course_code="course_code"
        :Year="season"
        :develop="develop"
        :config="config"
      />
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";
import { HBH, ScoreTeam } from "@ocs-software/vue-ocs";
import Post from "@/components/posts.vue";
import axios from 'axios';
export default {
  name: "Tournament",
  components: { ReportTable, HBH, ScoreTeam, Post },
  props: ["course_code", "id", "code", "config",],
  data() {
    return {
      tourinfo: [],
      class_group: '',
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      title: "",
      report: "",
      data: [],
      wptag: "",
    };
  },
  methods:{
    gettournPosts: function() {
      console.log("InPost")
      return axios.get(
        'https://wp-alpstour.ocs-sport.com/wp-json/wp/v2/' +
          "tags?slug=" + this.id + this.tourinfo.code + "&randomadd=" + new Date().getTime()
        )
      .then(response => {
        this.wptag = response.data;
        console.log("this.wptag")
        console.log(this.wptag)
        if (this.wptag[0].count > 0) {
          this.showNews = true
        }
          // if (condition) {
            
          // } else {
            
          // }
          // return axios.get(
          //   process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
          //     "wp/v2/tags?slug=" + this.id + this.tourinfo.code
          //   )
          // .then(response => {
          //   this.wptag = response.data;
          //   console.log("this.wptag")
          //   console.log(this.wptag)
          //   if (this.wptag[0].count > 0) {
          //     this.showNews = true
          //   }})

      });
    },
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      (this.report = report), (this.title = title);
      return apiCall
        .report(this.reportTitle)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
   inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  computed: {
    // mapRef: function() {
    //   if (this.tourinfo.clubs.length)
    //     return this.tourinfo.clubs.clubs_entry.rep;
    //   else return this.tourinfo.course_name;
    // },

    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    currentReport: function(cr) {
      if (this.report === "") {
        if (Array.isArray(this.tourinfo.reports.reports_entry)) {
          cr = this.tourinfo.reports.reports_entry[0].report_url;
        } else cr = this.tourinfo.reports.reports_entry.report_url;
      } else cr = this.report;
      return cr;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_APIFULL_URL +
          this.id +
          "/" +
          this.id +
          "-" +
          this.course_code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );
          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              process.env.VUE_APP_APIFULL_URL +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_APIFULL_URL +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_APIFULL_URL +
              this.id +
              "/" +
              this.id +
              "-" +
              this.course_code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_APIFULL_URL +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_APIFULL_URL +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_APIFULL_URL +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  // created(id, code) {
  //   id = this.id
  //   code = this.course_code
  //   apiCall
  //     .tmParams(id, code)
  //     .then(({ data }) => {
  //       this.tourinfo = data;
  //       this.loading = false;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       throw error;
  //     });
  // },
  async mounted(id, code) {
    this.title = "Tournament Entries";
    id = this.id
    code = this.course_code
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParams(id, code)
      this.tourinfo = first_call.data;
      this.until = first_call.data.days_away;
      this.showGender = first_call.data.show_gender;
      this.is_qualifier = first_call.data.is_qualifier;
      this.stableford = first_call.data.stableford;
      this.show_info_web = first_call.data.tm_params.show_info_web;
      console.log("this.show_info_web")
      console.log(this.show_info_web)
      this.hide_score_card = first_call.data.hide_score_card;
      this.class_group = first_call.data.class_group;
        console.log("this.class_group")
        console.log(this.class_group)
      if (this.class_group == 'ALP') {
       this.digitalOceanURL = first_call.data.tm_params.DEFIMAGESAMS3; 
      }
      console.log("this.digitalOceanURL")
      console.log(this.digitalOceanURL)
      this.loading = false;
      let second_call = await apiCall.report(this.reportTitle);
      this.data = second_call.data;
      console.log("data");
      console.log(this.data);
      console.log("showGender");
      console.log(this.showGender);
      this.gettournPosts()
      // Second call
      if (this.class_group !== 'ALP') {
        let second_call = await apiCall.tmParamsqsc(id, code)
        this.tourinfo = second_call.data;
        this.until = first_call.data.days_away;
        this.showGender = first_call.data.show_gender;
        this.is_qualifier = first_call.data.is_qualifier;
        this.stableford = first_call.data.stableford;
        this.show_info_web = first_call.data.tm_params.show_info_web;
        console.log("this.show_info_web")
        console.log(this.show_info_web)
        this.hide_score_card = first_call.data.hide_score_card;
        this.digitalOceanURL = second_call.data.tm_params.DEFIMAGESAMS3;
        this.class_group = second_call.data.class_group;
        console.log("this.class_group 747")
        console.log(this.class_group)
        this.loading = false; 
        let third_call = await apiCall.report(this.reportTitle);
        this.data = third_call.data;
        console.log("data");
        console.log(this.data);
        console.log("showGender");
        console.log(this.showGender);
        this.gettournPosts()
      } else {
        console.log('Error')
      }
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.outerBorderImage {
    border-radius: 50%;
    height: auto;
    width: auto;
    opacity: 0.9;
    margin-top: 0px;
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/White_Circle.png);
    background-repeat: no-repeat;
    background-size: contain;
}
h3.HeaderText.space {
  padding-bottom: 30px;
}
a.websiteLink {
  width: 20px;
  display: inline-block;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.marginRow {
  padding-top: 30px;
}
.showMobile > h2.date-width {
  color: #fff;
  z-index: 999;
  position: relative;
  margin-top: 20px !important;
}
.showMobile > h2.fullName-width {
  color: #fff;
  z-index: 999;
  position: relative;
}
.overflowImage.newsSmallImage {
  height: 190px;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
div#reportTable {
  height: 600px;
  overflow: scroll;
}
.tournNews {
  padding-bottom: 30px;
  padding-top: 30px;
}
.Allbuttons {
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #263056;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  float: right;
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
h2.HeaderText.scorecardSection {
  padding-top: 100px;
}
::v-deep .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #ffffff!important;
  background-color: #263056;
  border-color: #263056 #263056 #fff;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
::v-deep table.scores-only-table > thead > tr > th:first-child {
  border-radius: 0px;
}
::v-deep table.scores-only-table > thead > tr > th:last-child {
  border-top-right-radius: 30px;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem !important;
}
::v-deep .hbh table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  /* background: #1a2338; */
  color: #ffffff !important;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px !important;
}
::v-deep .TitleArea {
  display: none;
}
::v-deep th:first-child {
  text-align: center;
  border-top-left-radius: 30px;
}
::v-deep th:last-child {
  border-top-right-radius: 30px;
}
::v-deep div#entries > div {
  display: none;
}
::v-deep div#draw > div {
  display: none;
}
::v-deep div#score > div {
  display: none;
}
::v-deep div#Results > div {
  display: none;
}
::v-deep div#hbh > div > div {
  display: none;
}
::v-deep div#reportTable > div {
  display: none;
}
h2.HeaderText.reportInfo {
  color: #263056;
  font-weight: 600;
  font-size: 26px;
  padding-right: 1em;
  position: relative;
  top: 9px;
}
.reportdrop {
  display: inline-flex;
  text-align: center;
  margin: auto;
  position: relative;
  left: 27em;
  margin-bottom: 40px;
}
::v-deep  .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* text-align: right; */
  float: right;
  position: relative;
  top: 10px;
}
::v-deep button.btn.dropdown-toggle.btn-secondary {
  float: right;
  width: 473px;
  height: 50px;
  background: transparent;
  color: #707070;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
  font-size: 16px;
  text-align: left;
}
.top:before {
  content: "";
  position: absolute;
  overflow: hidden;
  width: 100%;
  /* max-height: 398px; */
  max-height: 500px;
  height: 100%;
  top: 10em;
  background-color: rgba(0, 0, 0, 0.5);
}
img.sponsorImage {
  width: 50%;
  max-width: fit-content;
  /* position: relative; */
  top: 40px;
  left: 65px;
  position: absolute;
}
h2.date-width {
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
}
a.playerProfileLink {
  color: #263056!important;
  font-size: 18px;
  font-weight: bold;
  /* position: relative;
  top: 50px; */
  text-align: center;
  margin: auto;
  display: block;
  /* margin-top: 36px; */
  margin-top: 22px;
}
a.playerProfileLink:hover {
  color: #707070!important;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  /* position: relative;
  top: 50px; */
  text-align: center;
  margin: auto;
  display: block;
  /* margin-top: 36px; */
  margin-top: 22px;
}
img.insideflag {
  width: 25px;
  text-align: center;
  margin: auto;
  display: block;
}
.imagePad {
  padding-right: 0;
}
.winnerNameBlock {
  background: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.noPadLeft.col-lg-12 {
  padding-left: 0;
}
.courseInformation {
  /* padding-left: 15px; */
  padding-top: 30px;
}
span.firstOption {
  position: relative;
  left: 31px;
}
.title {
  text-align: center;
  background-color: #F8F8F8;
  color: #707070;
}
th {
  font-size: 16px;
}
th:first-child {
  border-top-left-radius: 30px;
}
th:last-child {
  border-top-right-radius: 30px;
}
.HeaderText {
  color: #263056;
  font-weight: 600;
  font-size: 26px;
}
.backgroundGrey {
  background-color: #F8F8F8;
  padding-bottom: 35px;
}
.tourndesc {
  /* padding-left: 15px; */
  font-size: 16px;
}
.tourInfoOverview {
  margin-top: 2em;
}
h2.tourInfoHeading{
  font-size: 25px;
  font-weight: 400;
  color: #848792;
  padding-left: 15px;
}
.noLink > a {
  display: contents;
}
.ReportsBlock {
  margin-bottom: 30px;
}
.paragraph-Padding {
  /* padding-left: 30px;
  padding-right: 30px; */
  padding-top: 30px;
  padding-bottom: 30px;
}
.top {
  /* height: 395px; */
  height: 500px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tour {
  /* padding-top: 20px; */
}
.holes {
  padding: 0 10px;
  padding-top: 25px;
  padding-bottom: 45px;
}
.rightside-changes {
  margin-bottom: 20px;
}
h3.valuesize {
  font-size: 16px;
  color: #707070;
}
h4.courseName-width {
  font-size: 26px;
  font-weight: bold;
}
h2.fullName-width {
  width: 100%;
  font-size: 42px;
  font-weight: bold;
}
.splash {
  width: 100%;
}
.leftCol > .row {
  padding-left: 15px;
  padding-right: 15px;
}
.underLine {
  /* text-decoration: underline; */
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.courseinfo {
  width: 100%;
}

.splashText {
  margin-top: 75px;
  color: white;
}

.logo {
  /* width: 170px; */
  width: 120px;
  padding-bottom: 25px;
  float: right;
  margin-top: 60px;
}

.overlay {
  /* background-color: rgba(38, 48, 86, 0.6); */
  padding-left: 4%;
  padding-top: 4%;
}

.splashPad {
  text-align: left;
  color: #263056;
  /* background-color: #263056; */
  font-weight: 600;
  font-size: 26px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.MainInfo {
  padding-left: calc(3vw + 3vh);
  padding-top: calc(2vw + 2vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(2vw + 2vh);
}

.courseStats {
  padding: 10px;
  margin-bottom: 2em;
  margin-top: 1em;
}

.rowPad {
  padding-bottom: 1%;
}
tr:nth-of-type(odd) {
  background-color: #fff!important;
}
thead > tr {
  background-color: white !important;
  font-weight: bold;
  font-size: 16px !important;
  border: 0px solid;
}
td:first-child {
  text-align: left;
}
::v-deep tr {
  border-bottom: 0px solid #E8E8E8;
}
td {
  padding-top: 12px !important;
  padding-right: 6px !important;
  padding-bottom: 12px !important;
  vertical-align: middle;
  font-size: 16px !important;
  color: #707070;
}
.reportButton {
  font-size: calc(0.5vh + 0.5vw);
  /* padding-left: 0; */
  /* margin-top: 0; */
  display: block;
  text-align: center;
  background: #263056;
  color: #fff;
  margin-bottom: 15px;
}
.reportButton:hover {
  font-size: calc(0.5vh + 0.5vw);
  /* padding-left: 0; */
  /* margin-top: 0; */
  display: block;
  text-align: center;
  background: #337ab7;
  color: #fff;
  margin-bottom: 15px;
}
.reportButton > a {
  text-decoration: none!important;
  color: #fff!important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.courseStats > .col:first-child {
  padding-right: 0;
}
.courseStats > .col:last-child {
  padding-left: 0;
}
.mainImg {
  /* max-width: 100%; */
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

#gmap_canvas {
  height: 300px;
  width: 300px;
}
.desc {
  font-size: 14px;
  padding: 35px;
}

@media only screen and (max-width: 1199px) {
  ::v-deep .top:before {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 100%;
    /* max-height: 398px; */
    max-height: 500px;
    height: 100%;
    top: 7.5em;
    background-color: rgba(0, 0, 0, 0.5);
  }
  a.playerProfileLink {
    color: #263056 !important;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    margin: auto;
    display: block;
    /* margin-top: 36px; */
    margin-top: 12px;
  }
}
@media only screen and (max-width: 1024px) {
  #gmap_canvas {
    height: 250px;
    width: 200px;
  }
  .splash {
    height: 300px;
  }
  .top {
    /* height: 300px; */
  }
  .splashText {
    margin-top: -25%;
  }

  .splashPad {
    /* padding-top: 8%; */
    padding-top: 4%;
    padding-bottom: 4%;
    font-size: 12pt;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1em;
  }
}
@media only screen and (max-width: 768px) {
  .holes {
    padding: 0 10px;
    padding-top: 15px;
    padding-bottom: 45px;
  }
  td {
    /* padding-top: 11px !important; */
    padding-right: 6px !important;
    /* padding-bottom: 11px !important; */
    vertical-align: middle;
    font-size: 16px !important;
  }
  #gmap_canvas {
    height: 250px;
    width: 300px;
  }
  .splashText {
    margin-top: -31%;
  }
  thead > tr > th {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
}
@media only screen and (min-width: 991px) {
  .showMobile {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  li.nav-item.nonactive.HBH {
    display: none;
  }
  .paraText {
    font-size: 14pt;
    padding-left: 15px;
    padding-right: 15px;
  }
  .showMobile {
    padding: 20px;
  }
  .top:before {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 100%;
    /* max-height: 398px; */
    max-height: 183px;
    height: 100%;
    top: 7em;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .desktopOnly {
    display: none
  }
  h2.fullName-width {
    width: 100%;
    font-size: 28px;
    font-weight: bold;
  }
  .mainImg {
    /* max-width: 100%; */
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0px;
  }
  h2.date-width {
    font-size: 15px;
    font-weight: bold;
    margin-top: 130px;
  }
  .winnerNameBlock {
    background: #fff;
    border-bottom-left-radius: 30px; 
    border-bottom-right-radius: 30px;
    padding-bottom: 25px;
    border-top-right-radius: 0px;
    border: 1px solid #263057;
    border-top: 0px;
  }
  ::v-deep button.btn.dropdown-toggle.btn-secondary {
    float: none;
    height: 50px;
    background: transparent;
    color: #707070;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    font-size: 16px;
    text-align: left;
    margin-top: 30px;
    width: 100%;
  }
  .reportdrop {
    display: block;
    text-align: center;
    margin: auto;
    position: relative;
    margin-bottom: 40px;
    left: 0;
  }
  .showDesk {
    display: none
  }
  .HeaderText {
    text-align: center;
    color: #263056;
    font-weight: 600;
    font-size: 26px;
    padding-top: 20px;
  }
  .Allbuttons {
    float: none;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #263056;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    margin: auto;
    margin-top: 30px!important;
    display: block;
  }
  .Allbuttons:hover {
    float: none;
    width: 244px;
    height: 50px;
    background: #263056;
    color: #fff;
    font-weight: 500;
    border-radius: 30px;
    border: 1px solid #E8E8E8;
    margin: auto;
    margin-top: 30px!important;
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  h2.fullName-width {
    width: auto;
  }
  .splash {
    height: 175px;
  }
  .top {
    height: 500px;
  }

  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.3em;
  }
  .reportButton {
    font-size: 1em;
    margin-right: 10px;
  }
  .reportRow {
    padding-bottom: 10%;
  }
  .logo {
    width: 80px;
    padding: 12px;
  }
  .top {
    height: 175px;
  }
  .splashText[data-v-14fba303] {
    margin-top: -38%;
  }
  .row {
    margin: 0;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
