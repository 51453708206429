<template>
  <div>
    <div v-if='this.$route.name === "contact"' class="contact container">
      <b-row>
        <b-col lg='4' md='4' cols='12'>
          <span v-html="contact.acf.address_info"></span>
        </b-col>
        <b-col lg='8' md='8' cols='12'>
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                id="gmap_canvas"
                :src="config.VUE_APP_MAPS_URL+'=68%20rue%20Anatole%20France%2092230%20Levallois-Perret%20France&t=&z=13&ie=UTF8&iwloc=&output=embed'"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe
              ><a href="https://yt2.org" rel="noopener" target="_blank"></a><br />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="contact container" v-if='this.$route.name === "about"'>

      <span v-html="contact.content.rendered"></span>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "contact",
  props:['config'],
  data() {
    return {
      contact: [],
    };
  },
  created() {
    if (this.$route.name === "contact") {
      const json = "wp/v2/pages/" + '18';
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.contact = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        }); 
    } else {
      const json = "wp/v2/pages/" + '16';
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.contact = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  },
};
</script>

<style scoped>
::v-deep h2 {
  font-weight: bold;
  font-size: 26px;
  color: #263056;
  padding-bottom: 30px;
  display: none
}
::v-deep li {
  font-size: 16px;
  color: #848792;
}
::v-deep h2, .h2 {

}
::v-deep h3 {
  font-size: 16px;
  color: #707070 !important;
  padding-bottom: 30px;
}
::v-deep h3 > strong{
  padding-bottom: 5px;
  display: block;
  color: #263056;
    font-size: 26px;
}
::v-deep p{
  margin: 0 0 10px;
  font-size: 16px;
  text-align: left!important;
  color: #707070;
}
.contact {
  padding-top: 5%;
  /* padding-left: 5%;
  padding-right: 5%; */
  padding-bottom: 5%;
}

iframe {
  /* width: 600px; */
  width: 100%;
  height: 500px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  /* width: 600px; */
}
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  /* width: 600px; */
}
@media only screen and (max-width: 480px) {
  iframe {
    width: 350px;
    height: 350px;
  }
  .mapouter {
    text-align: left;
    height: 350px;
    width: 350px;
  }
  .gmap_canvas {
    height: 350px;
    width: 350px;
  }
  .contact{
    padding: 41px;
  }
}
</style>
