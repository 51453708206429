<template>
  <div class="">
    <!-- Player Profile Top Information Section -->
    <div class="playerProfile ">
      <!-- Above 1200px -->
      <b-container class="above1200px">
        <b-row>
          <b-col class="playerImageOne" lg="4" v-bind:style="{ 'background-image': 'url(' + digitalOceanURL + config.VUE_APP_ID + '/media/photos/' +
                playerinfo.MEMBER.PHOTOS.PHOTO.PHOTO_IMAGE + ')' }">
          </b-col>
          <b-col lg="8" class="playerTextOne">
            <b-row>
              <b-col>
                <div class="playersName">
                  {{ playerinfo.MEMBER.FULL_NAME }}
                      <!-- <span v-if="show_name_pronounce">
                        <b-button @click="namePronounce()" class="namePronounce"
                          ><font-awesome-icon
                            class="icon"
                            :icon="['fa', 'volume-up']"
                        /></b-button>
                      </span> -->
                </div>
              </b-col>
              <b-col>
                <img
                  class="flagPlayer-profile"
                  :src="
                    (config.VUE_APP_FLAG_URL +
                      playerinfo.MEMBER.NATIONALITY)
                      | lowercase
                  "
                />
              </b-col>
            </b-row>
            <b-row class='topSectionInfoDepth'>
              <b-col>
                <h3 class="playerItem">Represents</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue">{{ playerinfo.MEMBER.REPRESENTS }}</h3>
              </b-col>
            </b-row>
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Age</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue">{{ playerinfo.MEMBER.AGE }}</h3>
              </b-col>
            </b-row>
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Height</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue" v-if="playerinfo.MEMBER.HEIGHT != ''">{{ playerinfo.MEMBER.HEIGHT }}</h3>
                <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row>
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Weight</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue" v-if="playerinfo.MEMBER.WEIGHT != ''">{{ playerinfo.MEMBER.WEIGHT }}</h3>
                <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row>
            <!-- <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Twitter</h3>
              </b-col>
              <b-col>
                 <h3 class="playerValue" v-if="playerinfo.MEMBER.TWITTER != ''">{{playerinfo.MEMBER.TWITTER}}</h3>
                 <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row> -->
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Instagram</h3>
              </b-col>
              <b-col>
                 <h3 class="playerValue" v-if="playerinfo.MEMBER.INSTAGRAM != ''">{{playerinfo.MEMBER.INSTAGRAM}}</h3>
                 <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <!-- Below 1200px -->
      <b-container class="below1200px">
        <b-row>
          <b-col class="playerImageOne" lg="12" v-bind:style="{ 'background-image': 'url(' + digitalOceanURL + config.VUE_APP_ID + '/media/photos/' +
                playerinfo.MEMBER.PHOTOS.PHOTO.PHOTO_IMAGE + ')' }">
          </b-col>
          <b-col lg="12" class="playerTextOne">
            <b-row>
              <b-col>
                <div class="playersName">
                  {{ playerinfo.MEMBER.FULL_NAME }}
                      <!-- <span v-if="show_name_pronounce">
                        <b-button @click="namePronounce()" class="namePronounce"
                          ><font-awesome-icon
                            class="icon"
                            :icon="['fa', 'volume-up']"
                        /></b-button>
                      </span> -->
                </div>
              </b-col>
              <b-col>
                <img
                  class="flagPlayer-profile"
                  :src="
                    (config.VUE_APP_FLAG_URL +
                      playerinfo.MEMBER.NATIONALITY)
                      | lowercase
                  "
                />
              </b-col>
            </b-row>
            <b-row class='topSectionInfoDepth'>
              <b-col>
                <h3 class="playerItem">Represents</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue">{{ playerinfo.MEMBER.REPRESENTS }}</h3>
              </b-col>
            </b-row>
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Age</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue">{{ playerinfo.MEMBER.AGE }}</h3>
              </b-col>
            </b-row>
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Height</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue" v-if="playerinfo.MEMBER.HEIGHT != ''">{{ playerinfo.MEMBER.HEIGHT }}</h3>
                <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row>
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Weight</h3>
              </b-col>
              <b-col>
                <h3 class="playerValue" v-if="playerinfo.MEMBER.WEIGHT != ''">{{ playerinfo.MEMBER.WEIGHT }}</h3>
                <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row>
            <!-- <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Twitter</h3>
              </b-col>
              <b-col>
                 <h3 class="playerValue" v-if="playerinfo.MEMBER.TWITTER != ''">{{playerinfo.MEMBER.TWITTER}}</h3>
                 <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row> -->
            <b-row class='topSectionInfoSecondDepth'>
              <b-col>
                <h3 class="playerItem">Instagram</h3>
              </b-col>
              <b-col>
                 <h3 class="playerValue" v-if="playerinfo.MEMBER.INSTAGRAM != ''">{{playerinfo.MEMBER.INSTAGRAM}}</h3>
                 <h3 class="playerValue" v-else>-</h3>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <template v-if="Object.keys(playerinfo.STATISTICS).length === 0 || Object.keys(playerinfo.STATISTICS) == 0">
          <b-row class="TopThreeInfo">
            <b-col></b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row class="TopThreeInfo">
            <b-col class="backgroundWhite" sm="12" lg="">
              <b-row>
                <b-col>
                  <h3 class="topthreeItem"> Tournament Wins</h3>
                  <h3 class="topthreesubItem" v-if="playerinfo.STATISTICS.STATISTIC[2].TOURNAMENTS != ''" >Tournaments {{playerinfo.STATISTICS.STATISTIC[2].TOURNAMENTS}}</h3>
                  <h3 class="topthreesubItem" v-else>Tournaments {{playerinfo.STATISTICS.STATISTIC[2].TOURNAMENTS}}</h3>
                </b-col>
                <b-col>
                  <h3 class="topthreeValue">{{playerinfo.STATISTICS.STATISTIC[2].VALUE}}</h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="backgroundWhite" sm="12" lg="">
              <b-row>
                <b-col>
                  <h3 class="topthreeItem"> Top 10 Finishes</h3>
                  <h3 class="topthreesubItem">Tournaments {{playerinfo.STATISTICS.STATISTIC[3].TOURNAMENTS}}</h3>
                </b-col>
                <b-col>
                  <h3 class="topthreeValue">{{playerinfo.STATISTICS.STATISTIC[3].VALUE}}</h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="backgroundWhite" sm="12" lg="">
              <b-row>
                <b-col>
                  <h3 class="topthreeItem"> Total Prize Money</h3>
                  <h3 class="topthreesubItem">Tournaments {{playerinfo.STATISTICS.STATISTIC[0].TOURNAMENTS}}</h3>
                </b-col>
                <b-col>
                  <h3 class="topthreeValue">{{playerinfo.STATISTICS.STATISTIC[0].VALUE}}</h3>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-container>
    </div>

    <!-- Player Profile Reports Section -->
    <b-container>
      <h3 class="headerText reportText">Select Report  <b-form-select v-model="selected" :options="options"></b-form-select></h3>

      <div class="mt-3">
        <template v-if="selected == 'a'">
          <PlayerStats :stats="playerinfo.STATISTICS.STATISTIC" />
        </template>
        <template v-else-if="selected == 'b'">
          <PlayerResults
            :playedSeasons="playerinfo.TIC_SEASONS.SEASON"
            :id='playerinfo.THIS_SEASON_RECORD.SEASON'
            :refno="refno"
            :config="config"
          />
        </template>
        <template v-else-if="selected == 'c'">
          <PlayerOrderofMerit :stats="playerinfo.ORDERS_OF_MERIT" />    
        </template>
        <template v-else-if="selected == 'd'">
          <PlayerThisSeasonRecord
            :stats="playerinfo.THIS_SEASON_RECORD.TOURNAMENT"
          />
        </template>
        <template v-else-if="selected == 'e'">
          <PlayerLastSeasonRecord
            :stats="playerinfo.LAST_SEASON_HIGHLIGHTS.TOURNAMENT"
          />
        </template>
        <template v-else-if="selected == 'f'">
          <PlayerCareerHighlights
            :stats="playerinfo.CAREER_HIGHLIGHTS.TOURNAMENT"
          />
        </template>
      </div>
    </b-container>

    <!-- Player News Section -->    
    <b-container>
      <div class="playerNewsSection">
        <h3 v-if="wptag.length" class="headerText newsTitle">Latest on {{ playerinfo.MEMBER.FULL_NAME }}</h3>
        <Post v-if="wptag.length"
          :tag="wptag[0].id" 
          :count="wptag[0].count" 
          :course_code="course_code"
          :Year="season"
          :develop="develop"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import PlayerStats from "@/components/playerStats.vue";
import PlayerResults from "@/components/playerResults.vue";
import PlayerOrderofMerit from "@/components/playerOrderofMerit.vue";
import PlayerLastSeasonRecord from "@/components/playerLastSeasonRecord.vue";
import PlayerThisSeasonRecord from "@/components/playerThisSeasonRecord.vue";
import PlayerCareerHighlights from "@/components/playerCareerHighlights.vue";
import Post from "@/components/posts.vue";
import axios from 'axios';
export default {
  name: "playerprofile",
  components: {
    PlayerStats,
    PlayerResults,
    PlayerOrderofMerit,
    PlayerLastSeasonRecord,
    PlayerThisSeasonRecord,
    PlayerCareerHighlights,
    Post
  },
  props: ["refno", 'config'],
  data() {
    return {
      playerinfo: [],
      show_name_pronounce: false,
      name_pronounce: "",
      wptag: "",
      selected: 'a',
      options: [
        { value: 'a', text: 'Statistics' },
        { value: 'b', text: 'Results' },
        { value: 'c', text: 'Order of Merit' },
        { value: 'd', text: 'This Season record' },
        { value: 'e', text: 'Last Season Highlights' },
        { value: 'f', text: 'Career Highlights' },
      ]
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  created() {
    apiCall
      .playersInfo(this.refno)
      .then(({ data }) => {
        this.playerinfo = data;
        this.digitalOceanURL = data.GENERAL.DEFIMAGESAMS3;
        this.show_name_pronounce = true;
        this.gettournPosts();
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  methods: {
    gettournPosts: function() {
      console.log("InPost")
      return axios.get(
        'https://wp-alpstour.ocs-sport.com/wp-json/wp/v2/' +
          "tags?slug=" + this.refno + "&randomadd=" + new Date().getTime()
        )
      .then(response => {
        this.wptag = response.data;
        console.log("this.wptag")
        console.log(this.wptag)
        if (this.wptag[0].count > 0) {
          this.showNews = true
        }
          // if (condition) {
            
          // } else {
            
          // }
          // return axios.get(
          //   process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
          //     "wp/v2/tags?slug=" + this.id + this.tourinfo.code
          //   )
          // .then(response => {
          //   this.wptag = response.data;
          //   console.log("this.wptag")
          //   console.log(this.wptag)
          //   if (this.wptag[0].count > 0) {
          //     this.showNews = true
          //   }})

      });
    },
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
    namePronounce() {
      console.log("play name sound");
      var audio = new Audio(this.name_pronounce);
      audio.play();
    },
  },
};
</script>

<style scoped>
::v-deep .table td {
  color: #707070!important;
  border-top: 0px solid #dee2e6;
  font-size: 16px!important;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 0px solid #dee2e6;
}
::v-deep .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 0px solid #dee2e6;
}
h3.headerText.reportText {
  display: block;
  margin: auto;
  text-align: center;
}
::v-deep .custom-select {
  display: inline-block;
  width: 300px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right 0.75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: auto;
  height: 52px;
  background: transparent;
  color: #8b8a8a;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
  margin-left: 20px;
}
.playersName {
  color: #263056;
  font-size: 28px;
  font-weight: bold;
}
h3.headerText.newsTitle {
  text-align: left;
}
h3.headerText {
  font-size: 26px;
  font-weight: bold;
  color: #263056;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 5px;
}
h3.topthreeValue {
  font-weight: bold;
  font-size: 40px;
  float: right;
  position: relative;
  top: 5px;
  color: #263056;
}
h3.topthreesubItem {
  color: #4E5F9B;
  font-weight: 500;
  font-size: 16px;
}
h3.topthreeItem {
  color: #263056;
  font-size: 26px;
  font-weight: bold;
}
.backgroundWhite {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 25px;
  margin: 10px;
  padding-top: 35px;
  padding-bottom: 35px;
}
.TopThreeInfo {
  padding-top: 50px;
}
.row.topSectionInfoSecondDepth {
  margin-top: 20px;
}
.row.topSectionInfoDepth {
  margin-top: 40px;
}
h3.playerItem {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}
h3.playerValue {
  font-size: 16px;
  color: #707070;
  font-weight: 400;
}
.playerTextOne.col-lg-8 {
  background-color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-left: 150px!important;
  padding: 50px;
}
.playerImageOne.col-lg-4 {
  height: 420px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.playerTextOne.col-lg-12 {
  background-color: #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 150px!important;
  padding: 50px;
}
.playerImageOne.col-lg-12 {
  height: 620px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
button.btn.namePronounce.btn-secondary {
  background-color: #263056;
  padding: 0.275rem 0.75rem;
}
.profileImage {
  width: 100%;
}
#sortDown {
  position: relative;
  left: 10px;
  top: -2px;
}
.playerProfile{
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2024/11/Header-Bar.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.fRow{
  border-bottom: 1px solid #ddd;
  margin-right: -10px;
}
.playDet > .col {
  border-left: 1px solid #ddd;
}
.playDet > .col:first-child {
  border: none;
}
.playDet {
  padding-top: 20px;
}
p.FullName-details {
  font-size: 20pt;
}
.playerstats-info {
  margin-top: 30px;
}
.flagPlayer-profile {
  width: 44px;
  /* margin-left: 15px; */
  box-shadow: 0 0 3px #555;
  float: right;
  margin-top: 20px;
}
p.HeaderTitle {
  font-weight: bold;
  color: #263056;
}
.PlayerIndex-backBtn {
  float: right;
  margin-right: 100px;
  font-size: 22px;
}
.playerImg {
  max-width: 20%;
  padding-left: 5%;
}

.btn {
  background-color: #263056;
  border-color: #263056;
  border-radius: 0;
}

.btn:hover {
  background-color: white;
  border-color: white;
  color: #263056;
}

.btn-info:focus {
  background-color: white;
  border-color: white;
  color: #263056;
  box-shadow: none;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #263056;
  background-color: white;
  border-color: white;
}

.mainImg {
  max-width: 75%;
}
.card-body{
  padding: 0;
}
.card-header{
  padding: 0!important;
}
::v-deep .table thead th {
  font-size: 16px!important;
  font-weight: bold;
}
::v-deep .table thead th:first-child {
  border-top-left-radius: 30px;
}
::v-deep .table thead th:last-child {
  border-top-right-radius: 30px;
}
@media only screen and (min-width: 1201px) {
  .below1200px.container {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .above1200px.container {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .playerImageOne {
    border-radius: 0!important;
  }
  .playerTextOne.col-lg-8 {
    border-radius: 0!important;
    padding-left: 50px !important;
  }
  .playerProfile {
    padding-top: 0px;
  }
  .mainImg {
    max-width: 75%;
    display: none;
  }
  .custom-select {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .playerImg {
    max-width: 75%;
    padding-bottom:20px;
  }
  .basicInfo {
    padding-left: 38px;
  }
  .PlayerIndex-backBtn {
    margin-right: 100px;
    font-size: 16px;
    padding-top: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .PlayerIndex-backBtn {
    margin-right: 5px;
  }
  .mobPad{
    padding-left: 0;
  }
  p.FullName-details{
    font-size: 20px;
  }
  .flagPlayer-profile {
    margin-left: 3px!important;
  }
  .playDet > .col:first-child {
    border: none;
    padding-left: 0px;
  }
  .playDet > .col:last-child {
    padding-right: 0px;
  }
  .playDet {
    padding-top: 20px;
    margin-right: 0;
  }
  ::v-deep th {
    padding: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 17px;
  }
}
</style>
