<template>
  <div>
    <div class="backgroundGrey">
      <b-container class="newslist">
        <b-row>
          <b-col
            lg="6"
            id="mobilePost"
            v-for="(posts, index) in posts.slice(0, 2)"
            :key="index"
            class="colsMargin"
          >
            <b-nav-item :to="'/news/' + posts.id">
              <div class="overflowImage">
                <b-img
                  :class="postImg"
                  :src="
                      posts.better_featured_image.source_url
                  "
                  fluid
                  alt="Responsive image"
                />
              </div>
              <div class="FirstPostContentBlock">
                <p class="date">{{dateTime}}</p>
                <h1 class="postsTitle LeftImage">
                  <span v-html="posts.title.rendered"></span>
                </h1>
              </div>
              <!-- <span class="postText" v-html="posts.excerpt.rendered"></span> -->
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="whiteBK">
      <b-container>
        <b-row>
          <b-col lg="6" sm="12">
            <h1 class="heading">All News</h1>
          </b-col>
          <b-col  lg="6" sm="12">
            <b-link to="/news" class="allNewsLink">
              <button class="Allbuttons">
                See All News
              </button>
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col 
            lg="6"
            id="mobilePost"
            v-for="(posts, index) in posts.slice(2,8)"
            :key="index">
              <b-nav-item :to="'/news/' + posts.id" class="bottomLinks">
                <b-row>
                  <b-col lg="8" sm="12">
                    <p class="date">{{dateTime}}</p>
                    <h1 class="postsTitle rightTitles">
                      <span v-html="posts.title.rendered"></span>
                    </h1>
                  </b-col>
                  <b-col lg="4" sm="12">
                    <div class="overflowImageSecond imageRight" v-bind:style="{ 'background-image': 'url(' + posts.better_featured_image.source_url + ')' }">
                    </div>
                  </b-col>
                </b-row>
                <!-- <span class="postText" v-html="posts.excerpt.rendered"></span> -->
              </b-nav-item>
          </b-col>
        </b-row>
        <b-row v-if="showAllnews == true">
          <b-col 
            lg="6"
            id="mobilePost"
            v-for="(posts, index) in posts.slice(8,50)"
            :key="index">
              <b-nav-item :to="'/news/' + posts.id" class="bottomLinks">
                <b-row>
                  <b-col cols="8">
                    <p class="date">{{dateTime}}</p>
                    <h1 class="postsTitle rightTitles">
                      <span v-html="posts.title.rendered"></span>
                    </h1>
                  </b-col>
                  <b-col>
                    <div class="overflowImageSecond imageRight" v-bind:style="{ 'background-image': 'url(' + posts.better_featured_image.source_url + ')' }">
                    </div>
                  </b-col>
                </b-row>
                <!-- <span class="postText" v-html="posts.excerpt.rendered"></span> -->
              </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
      <div class="SeeMorebtn">
        <b-link class="allNewsLink">
            <button class="AllbuttonsSeeAll">
              <template v-if="showAllnews == true">
                <div @click="showLessNews();" >
                  See Less
                </div>
              </template>
              <template v-else >
                <div @click="showMoreNews();">
                  See More
                </div>
              </template>
            </button>
        </b-link>
      </div>
    </div>
  </div>
  
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "posts",
  props: ["config"],
  data() {
    return {
      posts: [],
      showAllnews: ''
    };
  },
  computed: {
    dateTime:function(){
      var dateOG = this.posts[0].date.split('T')
      var splitDate = dateOG[0].split('-')
      var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
      // var time = dateOG[1]
      return 'Published ' + newDate

    }
  },
  methods: {
    showMoreNews: function() {
      this.showAllnews = true;
    },
    showLessNews: function() {
      this.showAllnews = false;
    }
  },
  created() {
    const json = "wp/v2/posts?randomadd=" +
          new Date().getTime();
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.SeeMorebtn {
  margin: auto;
  text-align: center;
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;
}
a.allNewsLink.router-link-exact-active.router-link-active {
  position: relative;
  top: -11px;
}
.colsMargin.col-lg-6 {
  padding-right: 0px;
  padding-left: 0px;
}
.overflowImageSecond.imageRight {
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
div#mobilePost {
  padding-bottom: 40px;
}
li.nav-item.bottomLinks > a {
  padding-left: 0px;
  padding-right: 0px;
}
.whiteBK {
  /* padding-top: 30px; */
  padding-top: 100px;
}
.backgroundGrey {
  background-color: #F8F8F8;
  padding-bottom: 50px;
  padding-top: 60px;
}
.AllbuttonsSeeAll {
  width: 244px;
  height: 50px;
  background: transparent;
  color: #263056;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
}
.AllbuttonsSeeAll:hover {
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
}
.Allbuttons {
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #263056;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
}
.Allbuttons:hover {
  float: right;
  width: 244px;
  height: 50px;
  background: #263056;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
}
.heading{
  font-weight: 600;
  font-size: 26px;
  color: #263056;
}
.FirstPostContentBlock {
  border: 1px solid #E8E8E8;
  width: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 35px;
  height: 200px;
  background: #fff;
}
p.date {
  color: #B2B2B2;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}
h1.postsTitle.LeftImage {
  font-weight: 600;
}
h1.postsTitle.rightTitles {
  font-size: 26px;
}
h1.postsTitle.rightTitles > span {
  font-size: 26px;
}
img.imageRight.img-fluid.postImg {
  border-radius: 50px;
  max-width: 100%;
  height: 188px;
  width: auto;
  float: right;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  /* font-size: 32px; */
  font-size: 28px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* white-space: nowrap; */
  /* width: 350px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  color: #263056;
  font-weight: 600;
}
.overflowImage {
  overflow: hidden;
  /* height: 278px; */
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  /* max-height: 425px; */
  max-height: 389px;
}
.overflowImageSecond {
  overflow: hidden;
  /* height: 278px; */
  border-radius: 50px;
  max-height: 425px;
}
#mobilePost:last-child {
  /* padding-right: 0px; */
}

.postRow {
  padding-bottom: 10px;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
  text-align: left;
}
.moreNews>a{
  background-color: transparent;
  color: #263056!important;
}
#leftMore{
  color: #fff;
  background-color: #263056;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  padding: 3px;
}


@media only screen and (max-width: 990px) {
  .FirstPostContentBlock {
    height: auto;
  }
  .whiteBK {
    padding-top: 30px;
  }
  .Allbuttons {
    float: none;
    width: 244px;
    height: 50px;
    background: transparent;
    color: #263056;
    font-weight: 500;
    border-radius: 50px;
    border: 1px solid #E8E8E8;
    margin: auto;
    margin-top: 30px;
    text-align: center;
    display: block;
  }
  .Allbuttons:hover {
    float: none;
    width: 244px;
    height: 50px;
    background: #263056;
    color: #fff;
    font-weight: 500;
    border-radius: 50px;
    border: 1px solid #E8E8E8;
    display: block;
  }
  .heading{
    font-weight: 600;
    font-size: 26px;
    color: #263056;
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  ::v-deep .nav-link {
    width: 100%;
  }
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
}
@media only screen and (max-width: 1165px) {
  #mobilePost:last-child {
    padding-right: 15px;
    margin-top: 2em;
  }
  div#mobilePost {
    margin-top: 1em;
  }
}
</style>
