<template>
  <div class="Photo">
    <Photos/>
  </div>
</template>
<script>
import Photos from '@/components/photos.vue'

export default {
  name:'Photo',
  components:{
    Photos
  }
}
</script>

<style scoped>
 .youTube{
   padding: 60px 100px;
 }
 @media only screen and (max-width: 480px) {
  .youTube{
   padding: 60px 10px;
 }
}
</style>
