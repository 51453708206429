<template>
  <div class="container photos">
    <h2 class="heading">Tournament Photos</h2>
    <div v-for="(photo, index) in photos" :key="index">
      <h2 class="tournLink" v-html="photo.title"></h2>
      <b-row>
        <b-col v-for="(text, index) in photo.photo_link" :key="index" lg="3" sm="12">
          <b-link :href="text.link" target="_blank" >
            <p class="roundText-color" v-html="text.text"></p>
            <div class="bkimage" v-bind:style="{ 'background-image': 'url(' + text.image + ')' }">
                <!-- <p v-html="text.link"></p> -->
            </div>
          </b-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "photos",
  props:['config'],
  data() {
    return {
      photos: [],
    };
  },
  created() {
      const json = "wp/v2/pages/" + '107' + "?randomadd=" + new Date().getTime();
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.data = data
          if (Array.isArray(this.data.acf.photo_section)) {
            this.photos = data.acf.photo_section;
            console.log("this.photos is array")
            console.log(this.photos)
          } else {
            this.photos = [data.acf.photo_section];
            console.log("this.photos is not")
            console.log(this.photos)
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
  },
};
</script>

<style scoped>
.heading {
  font-weight: 600;
  font-size: 26px;
  color: #263056;
}
::v-deep h2 {
  font-weight: bold;
  font-size: 26px;
  color: #263056;
  padding-bottom: 30px;
  padding-top: 30px;
}
h2.tournLink {
  font-size: 18px;
  padding-bottom: 0;
  padding-bottom: 10px;
}
.bkimage {
  /* width: 100%; */
  height: 250px;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
}
p.roundText-color {
  color: #2b3660;
  font-size: 13pt;
}
</style>
