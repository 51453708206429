var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card-body',{staticClass:"text-center"},[_c('div',{staticClass:"table-responsive"},[_c('b-table',{attrs:{"items":_vm.filteredList,"fields":_vm.fields,"tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(reports)",fn:function(row){return [_c('b-nav',{attrs:{"card-header":"","pills":""}},[(row.item.reports.reports_entry == undefined)?void 0:[(row.item.ts_final_result == 'Y')?[_c('b-link',{attrs:{"to":{
                    name: 'reports',
                    query: {
                    url:
                      'https://ocs-ffg.com/tic/tmresult.cgi?tourn=' +
                      row.item.code +
                      '~season=' +
                      _vm.id +
                      '~result=PF~',
                      id: _vm.id,
                      code: row.item.code,
                      title: 'Final Result',
                    },
                  }}},[_c('button',{staticClass:"reportBTN"},[_vm._v(" Results ")])])]:(Array.isArray(row.item.reports.reports_entry))?_vm._l((row.item.reports.reports_entry.slice(-1)),function(rep,index){return _c('b-link',{key:index,attrs:{"to":{
                      name: 'reports',
                      query: {
                        url: rep.report_url,
                        id: _vm.id,
                        code: row.item.code,
                        title: rep.report_title,
                      },
                    }}},[_c('button',{staticClass:"reportBTN"},[(rep.report_title == 'Live Scoring Monitor')?[_vm._v(" Live Scores ")]:_vm._e()],2)])}):[_c('b-link',{attrs:{"to":{
                        name: 'reports',
                        query: {
                          url: row.item.reports.reports_entry.report_url,
                          id: _vm.id,
                          code: row.item.code,
                          title: row.item.reports.reports_entry.report_title,
                        },
                      }}},[_c('button',{staticClass:"reportBTN"},[_vm._v(" Entries ")])])]]],2)]}},{key:"cell(full_name)",fn:function(row){return [_c('b-nav-item',{staticClass:"tourLink",class:{major : row.item.major == 'Y' },attrs:{"to":'/tournament/' + row.item.code + '/' + _vm.id,"filter":_vm.filter}},[_vm._v(" "+_vm._s(row.item.full_name)+" ")])]}},{key:"cell(start_date)",fn:function(row){return [_c('span',{class:{major : row.item.major == 'Y'}},[_vm._v(" "+_vm._s(row.item.start_date)+" ")])]}},{key:"cell(course)",fn:function(row){return [_c('span',{class:{major : row.item.major == 'Y'}},[_vm._v(" "+_vm._s(row.item.course)+" ")])]}},{key:"cell(total_prize_fund)",fn:function(row){return [_c('span',{class:{major : row.item.major == 'Y'}},[_vm._v(" "+_vm._s(row.item.total_prize_fund)+" ")])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }