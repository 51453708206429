<template>
  <b-table
    v-if="Array.isArray(stats.OOM.SEASON)"
    :fields="fields"
    :items="stats.OOM.SEASON"
    style="width:100%"
  >
  </b-table>
  <table v-else style="width:100%">
    <thead>
      <tr>
        <th class="title">
          Season Name
        </th>
        <th class="title">
          Pos
        </th>
        <th class="title">
          Points
        </th>
        <th class="title">
          Tournament
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="cell">
          {{ stats.OOM.SEASON.SEAS_NAME }}
        </td>
        <td class="cell">
          {{ stats.OOM.SEASON.POSITION }}
        </td>
        <td class="cell">
          {{ stats.OOM.SEASON.VALUE }}
        </td>
        <td class="cell">
          {{ stats.OOM.SEASON.TOURNS }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "playerOom",
  props: ["stats"],
  data() {
    return {
      fields: [
        { key: "SEAS_NAME", label: "Season Name" },
        { key: "POSITION", label: "Pos" },
        { key: "VALUE", label: "Points" },
        { key: "TOURNS", label: "Tournament" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep thead {
  color: white;
  background-color: #263056;
}
.cell {
  text-align: center;
}
.title {
  text-align: center;
  background-color: #263056;
  color: white;
}
::v-deep tbody > tr:nth-child(even) {
  background-color: #F5F5F5;
}
::v-deep td{
  text-align: center;
  padding: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 14px;
}
::v-deep th{
  padding: 13px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
}

</style>
