<template>
  <b-table :fields="fields" :items="stats"> </b-table>
</template>

<script>
export default {
  name: "playerThisSeason",
  props: ["stats"],
  data() {
    return {
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "ROUNDS", label: "Rounds" },
        { key: "SCORE", label: "Score" },
        { key: "WINNINGS", label: "Winnings (EUR)" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep thead {
  color: white;
  background-color: #263056;
}
::v-deep tbody > tr:nth-child(even) {
  background-color: #F5F5F5;
} 
::v-deep td{
  text-align: center;
  padding: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 14px;
}
::v-deep th{
  padding: 13px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
}
::v-deep td:nth-child(2){
  text-align: left;
}
::v-deep th:nth-child(2){
  text-align: left;
}
</style>
