<template>
  <div>
    <div>
      <!-- <img class="sponsor mobHide" :src="data.event_sponsor_logo" /> -->
      <!-- <img class="sponsor mobHide" :src="data.event_sponsor_logo.replace('http://alps.ocs-ffg.com/', this.DEFIMAGESAMS3 + 'ffg' + '/').replace('https://alps.ocs-ffg.com/', this.DEFIMAGESAMS3 + 'ffg' + '/')" /> -->
    </div>
    <b-container>
      <div :class="drop">
        <b-nav card-header pills>
          <b-dropdown
            v-if="Array.isArray(tm_params.reports.reports_entry)"
            :text="title"
            class="dropdownReports"
          >
            <b-dropdown-item
              v-on:click="changeReport"
              v-for="(reports, index) in filterExemptions"
              :key="index"
              :title="reports.report_url"
              :value="reports.report_title"
              >{{ reports.report_title }}</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            v-else
            text="Please Select a Report"
            class="dropdownReports"
          >
            <b-dropdown-item
              v-on:click="changeReport"
              v-for="(reports, index) in tm_params.reports"
              :key="index"
              :text="title"
              :value="reports.report_title"
              >{{ reports.report_title }}</b-dropdown-item
            >
          </b-dropdown>
          <!-- <b-button onclick="window.print()" class="print">Print</b-button> -->
        </b-nav>
      </div>
      <b-row style="margin-left: 0">
        <b-col
          v-if="tm_params.multi_course === 'Y'"
          class="col-md-6 multi"
        >
          <span>Courses:</span>

          <span
            class="multiList"
            v-for="(multi, index) in tm_params.courses.courses_entry"
            :key="index"
          >
            <span :class="multi.course_X_colour">•</span
            ><span class="multiName">{{ multi.course_X_id }}</span>
          </span>
        </b-col>
      </b-row>
      <div class="reports">
        <div
          v-if="
            this.currentReport.indexOf('tmentry') !== -1 ||
              this.currentReport.indexOf('tmdraw') !== -1 ||
              this.currentReport.indexOf('tmresult') !== -1 ||
              this.currentReport.indexOf('tmoomt') !== -1
          "
        >
          <ReportTable
            :data="data"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="teamEvent"
            :match="tm_params.match_play"
            :showGender="showGender"
            :Stableford="Stableford"
            :represents_ind="represents_ind"
            :represents_heading="represents_heading"
            :represents_flag_type="represents_flag_type"
            :is_qualifier="is_qualifier"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </div>
        <div v-else-if="this.currentReport.indexOf('tmlsmon') !== -1 || this.currentReport.indexOf('tmscores') !== -1 || this.currentReport.indexOf('tmcrsts') !== -1">
          <b-tabs content-class="mt-3">
            <b-tab title="Leaderboard" active>
              <ReportTable
                :data="data"
                :previousData="previousData"
                :home="home"
                :title="title"
                :config="config"
                :team="teamEvent"
                :match="tm_params.match_play"
                :showGender="showGender"
                :Stableford="Stableford"
                :represents_ind="represents_ind"
                :represents_heading="represents_heading"
                :represents_flag_type="represents_flag_type"
                :is_qualifier="is_qualifier"
                :show_info_web="show_info_web"
                :hide_score_card="hide_score_card"
              />
            </b-tab>
            <b-tab title="Hole by Hole" :title-item-class="'showDesk'">
              <HBH 
                :tabs="tabs" 
                :config="config" 
                :title="title" 
                :scores="data.scores.scores_entry"
                :data="data"
                :show_info_web="show_info_web"
                :hide_score_card="hide_score_card"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
// import Live from "@/views/live.vue";
import ReportTable from "@/components/reportTable.vue";
import apiCall from "@/services/api.js";
import { HBH } from "@ocs-software/vue-ocs";
export default {
  name: "Reports",
  components: {
    // Live,
    ReportTable,
    HBH,
  },
  props: ["config"],
  data() {
    return {
      data: [],
      previousData: [],
      tm_params: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title,
      id: this.$route.query.id,
      code: this.$route.query.code,
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        this.callOne()
      );
    },
    updateStuff: async function() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      // this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParams(this.id, this.code);
      // this.DEFIMAGESAMS3 = second_call.data.tm_params.DEFIMAGESAMS3;
      this.tm_params = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
    },
    callOne() {
      apiCall
        .report(this.reportTitle)
        .then(({ data }) => {
          this.data = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  computed: {
    filterExemptions: function() {
      return this.tm_params.reports.reports_entry.filter(
        (reports_entry) =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_APIFULL_URL +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "alp" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "alp" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "alp" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmcrsts") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              "alp" +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomaddMATT=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "alp" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "alp" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            "alp" +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  created() {
    setInterval(this.updateStuff, 30000);
  },
    async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParams(this.id, this.code);
      this.tm_params = second_call.data;
      this.DEFIMAGESAMS3 = second_call.data.tm_params.DEFIMAGESAMS3;
      this.showGender = second_call.data.show_gender;
      this.Stableford = second_call.data.stableford;
      this.show_info_web = second_call.data.tm_params.show_info_web;
      this.hide_score_card = second_call.data.hide_score_card;
      this.is_qualifier = second_call.data.is_qualifier;
      this.livemessage = second_call.data.live_message;
      this.livemessageText = second_call.data.live_scoring_mess;
      this.playSuspended = second_call.data.play_suspended;
      console.log("this.DEFIMAGESAMS3")
      console.log(this.DEFIMAGESAMS3)
      console.log("this.tm_params")
      console.log(this.tm_params)
      // this.DEFIMAGESAMS3 = second_call.data.tm_params.DEFIMAGESAMS3;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep tr {
  border-bottom: 0px solid #E8E8E8;
}
::v-deep .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
span.multiName {
  padding-left: 5px;
  padding-right: 5px;
}
.B {
  color: #333333;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: middle;
}
.Y {
  color: #d8db22;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: middle;
}
.C {
  color: #1e90ff;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: middle;
}
.R {
  color: #bb0000;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: middle;
}
.G {
  color: #3c9933;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: middle;
}
.O {
  color: #e07020;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: middle;
}
.dropdownReports > button:hover {
  background-color: pink !important;
}

.drop {
  padding-left: 10px;
  padding-bottom: 50px;
  padding-top: 50px;
}
.sponsor {
  margin-top: -8em !important;
  margin-left: 10% !important;
  max-width: 136px;
  vertical-align: middle;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
::v-deep button {
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #263056;
  color: #ffffff;
  border-radius: 0px;
  border: 0px;
  height: 30px;
  width: 100%;
  text-align: left;
  float: right;
  width: 244px;
  height: 50px;
  background: transparent;
  color: #8b8a8a;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #E8E8E8;
}
::v-deep button::after {
  float: right;
  margin-top: 6px;
}
.dropdown {
  padding-right: 15px;
}
.print {
  display: inline-block;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 0.428571;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0px;
  color: #fff;
  background-color: #263056;
  border-color: #263056;
  width: 120px;
}
::v-deep button:hover {
  color: #fff;
  background-color: #263056;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #4E5F9B !important;
  width: 100%;
  border-color: #4E5F9B !important;
  height: 100%;
}

::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
@media only screen and (max-width: 990px) {
 ::v-deep li.nav-item.showDesk {
  display: none!important;
 }
.dropdown {
  width: 100%;
}
}
@media only screen and (max-width: 980px) {
  .mobHide {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
}
</style>
